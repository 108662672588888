import { createAction, nanoid } from '@reduxjs/toolkit'

import { type WithRequired } from '../../types'
import { type ErrorNotification, type Notification, type SuccessNotification } from './types'

type SimplifyNotification<T extends Notification> = WithRequired<Partial<T>, 'message'>

export const pushSuccessNotification = createAction(
  'notifications/successes/push',
  (notification: SimplifyNotification<SuccessNotification>) => ({
    payload: {
      id: notification.id ?? nanoid(),
      type: 'SUCCESS',
      message: notification.message,
      extra: notification.extra,
    },
  }),
)

export const pushErrorNotification = createAction(
  'notifications/errors/push',
  (notification: SimplifyNotification<ErrorNotification>) => ({
    payload: {
      id: notification.id ?? nanoid(),
      type: 'ERROR',
      message: notification.message,
      extra: notification.extra,
    },
  }),
)
