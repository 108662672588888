import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'

import { type ConfirmationOptions, type ConfirmFn } from '../../useConfirm.types'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'

export const ConfirmContext = createContext<ConfirmFn>(() => {
  throw Error('Confirmation not resolved')
})

const ConfirmContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const resolver = useRef<(isConfirmed: boolean) => void>()

  const [options, setOptions] = useState<ConfirmationOptions | null>(null)

  const confirm = useCallback(
    async (options: ConfirmationOptions): Promise<boolean> => {
      setOptions(options)

      return await new Promise<boolean>((resolve) => {
        resolver.current = resolve
      })
    },
    [resolver],
  )

  const handleConfirm = (): void => {
    setOptions(null)

    resolver.current?.(true)
  }

  const handleDecline = (): void => {
    setOptions(null)

    resolver.current?.(false)
  }

  const isOpen = options !== null

  return (
    <ConfirmContext.Provider value={confirm}>
      <ConfirmModal
        open={isOpen}
        options={options ?? undefined}
        onConfirm={handleConfirm}
        onDecline={handleDecline}
        onClose={handleDecline}
      />
      {children}
    </ConfirmContext.Provider>
  )
}

export default ConfirmContextProvider
