import Box from '@mui/joy/Box'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe, type Stripe } from '@stripe/stripe-js'
import { type FC, memo, useEffect, useState } from 'react'

import { useCheckoutMutation } from '../../../../../../store/endpoints/payment'
import { type PlanId } from '../PaymentPlans/PaymentPlans.type'

const PUBLIC_STRIPE_KEY =
  'pk_test_51NtAToCvSLNV5mliw7GHynLeQARKVc5cMOZQoTd8eir7VDyowI4k7t5Gj2T7Y00UjUcCIELZgKV5gzaeaRnsfEeA00X0VgKYv2'

const StripeAdapter: FC<{ planId: PlanId }> = ({ planId }) => {
  // const location = useLocation()
  // const searchParams = new URLSearchParams(location.search)
  // const isPaymentTransactionProcessing = searchParams.get('pt') === 'true' // pt is string !!!

  const [stripe, setStripe] = useState<Stripe | null>(null)

  const [checkout, { data: session = {} }] = useCheckoutMutation()
  const { clientSecret = null } = session

  const loadAndSetStripe = async (): Promise<void> => {
    const stripe = await loadStripe(PUBLIC_STRIPE_KEY)
    setStripe(stripe)
  }

  const checkoutPlan = async (): Promise<void> => {
    const domainInfo =
      window.location.origin !== undefined ? { redirectDomain: window.location.origin } : {}

    await checkout({ planId, ...domainInfo })
  }

  useEffect(() => {
    void loadAndSetStripe()
  }, [])

  useEffect(() => {
    void checkoutPlan()
  }, [planId])

  return (
    <Box
      id="stripe_checkout"
      sx={{
        width: 1080,
        height: 780,
        overflow: 'auto',
        borderRadius: '8px',
        padding: '16px',
      }}
    >
      {stripe !== null && clientSecret !== null && (
        <Box
          sx={{ overflow: 'auto', borderRadius: '8px', border: '1px solid rgba(26, 26, 26, 0.1)' }}
        >
          <EmbeddedCheckoutProvider key="stripe" stripe={stripe} options={{ clientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Box>
      )}
    </Box>
  )
}

export default memo(StripeAdapter)
