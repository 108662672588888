import FormControl from '@mui/joy/FormControl'
import Input from '@mui/joy/Input'
import Option from '@mui/joy/Option'
import Stack from '@mui/joy/Stack'
import { type ChangeEvent, type FC, memo } from 'react'
import { IoSearchSharp } from 'react-icons/io5'

import SocialAccountSelectV2 from '../../../../components/forms/SocialAccountSelectV2/SocialAccountSelectV2'
import { type CampaingsFilterProps } from './types'

const CampaignsFilter: FC<CampaingsFilterProps> = ({
  children,
  filter,
  onFilterChange,
  ...props
}) => {
  const handleSocialAccountChange = (_: unknown, newSocialAccountId: string | null): void => {
    if (newSocialAccountId === null) {
      return
    }

    const newFilter =
      filter !== undefined
        ? { ...filter, socialAccountId: newSocialAccountId }
        : { socialAccountId: newSocialAccountId }

    onFilterChange?.(newFilter)
  }

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newSearch = event.target.value
    const newFilter =
      filter !== undefined ? { ...filter, search: newSearch } : { search: newSearch }

    onFilterChange?.(newFilter)
  }

  return (
    <Stack direction="row" justifyContent="space-between" {...props}>
      <FormControl size="md">
        <SocialAccountSelectV2
          sx={{ width: 195 }}
          size="md"
          placeholder="Page is Any"
          value={filter?.socialAccountId}
          onChange={handleSocialAccountChange}
          renderValue={(option) => <>Page is {option !== null ? option?.label : 'Any'}</>}
        >
          <Option value="any">Any</Option>
        </SocialAccountSelectV2>
      </FormControl>
      {children}
      <FormControl size="md" sx={{ ml: 'auto' }}>
        <Input
          value={filter?.search}
          placeholder="Search"
          startDecorator={<IoSearchSharp size={18} />}
          onChange={handleSearchChange}
        />
      </FormControl>
    </Stack>
  )
}

export default memo(CampaignsFilter)
