import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Card from '@mui/joy/Card'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import type { ReactElement } from 'react'
import { TbLogout } from 'react-icons/tb'

import { useLogoutMutation } from '../../store/endpoints/auth'
import FacebookButtonContainer from './components/FacebookButtonContainer/FacebookButtonContainer'
import NewslettersContainer from './components/NewslettersContainer/NewslettersContainer'
import PaymentPlansContainer from './components/PaymentPlansContainer/PaymentPlansContainer'
import PaymentsHistoryContainer from './components/PaymentsHistoryContainer/PaymentsHistoryContainer'
import SocialAccountsContainer from './components/SocialAccountsContainer/SocialAccountsContainer'
import SourceAccountsContainer from './components/SourceAccountsContainer/SourceAccountsContainer'

const SettingsPage = (): ReactElement => {
  const [logout, { isLoading }] = useLogoutMutation()

  const handleLogoutClick = async (): Promise<void> => {
    await logout()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <Stack
        sx={{
          flex: 0,
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          p: 2.5,
        }}
      >
        <Typography level="h4">Settings</Typography>

        <Button
          variant="outlined"
          endDecorator={<TbLogout />}
          loading={isLoading}
          onClick={handleLogoutClick}
        >
          Log Out
        </Button>
      </Stack>

      <Stack sx={{ width: 650, gap: 3, mb: 2 }}>
        <Card variant="plain">
          <Stack gap={3.5}>
            <Typography level="h3">Billing</Typography>
            <PaymentPlansContainer />

            <Box>
              <Typography>Payment History</Typography>
              <PaymentsHistoryContainer />
            </Box>
          </Stack>
        </Card>

        <Card variant="plain" sx={{ p: 3 }}>
          <Stack gap={3.5}>
            <Stack direction="row" justifyContent="space-between">
              <Typography level="h3">Social pages</Typography>
              <FacebookButtonContainer>Add account</FacebookButtonContainer>
            </Stack>

            <SocialAccountsContainer />
          </Stack>
        </Card>

        <Card variant="plain" sx={{ p: 3 }}>
          <Stack gap={3.5}>
            <Typography level="h3">Account</Typography>

            <SourceAccountsContainer />
          </Stack>
        </Card>

        <Card variant="plain" sx={{ p: 3 }}>
          <Stack gap={3.5}>
            <Typography level="h3">Newsletter</Typography>

            <NewslettersContainer />
          </Stack>
        </Card>
      </Stack>
    </Box>
  )
}

export default SettingsPage
