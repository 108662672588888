import { Box } from '@mui/joy'
import Option from '@mui/joy/Option'
import Select, { type SelectProps } from '@mui/joy/Select'

import { useGetPostTemplatesQuery } from '../../../store/endpoints/postsTemplates'
import { Spinner } from '../../../ui'

const PostTemplateSelect = <Multiple extends boolean = false>(
  props: SelectProps<string, Multiple>,
): React.ReactNode => {
  const { data: postTemplates = [], isLoading: isPostTemplatesLoading } = useGetPostTemplatesQuery()

  return (
    <Select {...props}>
      {isPostTemplatesLoading && (
        <Option value="loading" disabled>
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Spinner size="sm" />
          </Box>
        </Option>
      )}

      {postTemplates.length === 0 && (
        <Option value="">
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            No templates
          </Box>
        </Option>
      )}

      {!isPostTemplatesLoading &&
        postTemplates?.map(({ id, name }) => (
          <Option key={id} value={id}>
            {name}
          </Option>
        ))}
    </Select>
  )
}

export default PostTemplateSelect
