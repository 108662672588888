import { type ReactElement } from 'react'

import StyledTable from './StyledTable'
import { type TableProps } from './types'

export default function Table<T>({
  items,
  renderHeader,
  renderRow,
  isLoading = false,
  renderSkeletonRow,
  renderEmptyTable,
}: TableProps<T>): ReactElement | null {
  const isEmpty = !Array.isArray(items) || items.length === 0

  if (isEmpty) {
    return renderEmptyTable === undefined ? null : renderEmptyTable()
  } else {
    if (renderHeader === undefined) {
      throw Error(`Can not render ${JSON.stringify(items)} without renderHeader`)
    }

    if (renderRow === undefined) {
      throw Error(`Can not render ${JSON.stringify(items)} without renderRow`)
    }
  }

  if (isLoading && renderSkeletonRow === undefined) {
    throw Error(`Can not render skeleton for ${JSON.stringify(items)} without renderSkeletonRow`)
  }

  return (
    <StyledTable.Container data-testid="campaign-table-container">
      {!isEmpty && (
        <StyledTable data-testid="campaign-table">
          <thead>{renderHeader()}</thead>

          <tbody>
            {!isLoading && items.map(renderRow)}

            {isLoading && renderSkeletonRow !== undefined && items.map(renderSkeletonRow)}
          </tbody>
        </StyledTable>
      )}
    </StyledTable.Container>
  )
}

// export default Table
