import { type FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import PostsFormSkeleton from '../../components/skeletons/PostsFormSkeleton/PostsFormSkeleton'
import RootBox from '../../components/views/RootBox/RootBox'
import useSocialAccounts from '../../hooks/useSocialAccounts'
import useSourceAccounts from '../../hooks/useSourceAccounts'
import { useCampaignQuery } from '../../store/endpoints/campaign'
import { useUserQuery } from '../../store/endpoints/root'
import CampaignsFormSubmitter from './CampaignsFormSubmitter'
import {
  initializeCampaignsFormByCampaign,
  initializeCampaignsFormByUser,
} from './CampaignsFormSubmitter.utils'
import CampaignNavigation from './components/CampaignNavigation/CampaignNavigation'
import { CampaignsSocialAccountsContext } from './hooks/useCampaignsSocialAccounts'
import { CampaignsStatusContext, getCampaignStatus } from './hooks/useCampaignStatus'
import useIsNewCampaignFlag from './hooks/useIsNewCampaignFlag'

const CampaignPage: FC = () => {
  const { id = 'new' } = useParams<{ id: string }>()

  const isNewCampaign = useIsNewCampaignFlag()

  const { data: campaign, isLoading } = useCampaignQuery(id, { skip: isNewCampaign })
  const { data: user, isLoading: isUserLoading } = useUserQuery()
  const { socialAccounts, isLoading: isSocialAccountsLoading } = useSocialAccounts()
  const { isLoading: isSourceAccountsLoading } = useSourceAccounts()

  const defaultCampaign = useMemo(() => {
    if (isNewCampaign) {
      if (user === undefined) {
        return
      }

      return initializeCampaignsFormByUser(user)
    }

    if (campaign === undefined) {
      return
    }

    return initializeCampaignsFormByCampaign(campaign)
  }, [isNewCampaign, user, campaign])

  return (
    <CampaignsSocialAccountsContext.Provider value={socialAccounts ?? []}>
      <CampaignsStatusContext.Provider value={getCampaignStatus(campaign?.status)}>
        <RootBox>
          <CampaignNavigation campaignName={campaign?.name} />
          {(isLoading && isUserLoading && isSocialAccountsLoading && isSourceAccountsLoading) ||
          defaultCampaign === undefined ? (
            <PostsFormSkeleton />
          ) : (
            <CampaignsFormSubmitter defaultCampaign={defaultCampaign} />
          )}
        </RootBox>
      </CampaignsStatusContext.Provider>
    </CampaignsSocialAccountsContext.Provider>
  )
}

export default CampaignPage
