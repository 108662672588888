export const normalizePostsFilterParams = (
  params: Record<string, string | number | undefined | null | string[]>,
): URLSearchParams => {
  const urlSearchParams = new URLSearchParams()

  for (const key in params) {
    if (
      params[key] === undefined ||
      params[key] === null ||
      params[key] === '' ||
      (params[key] as string[] | undefined)?.length === 0
    ) {
      continue
    } else {
      if (typeof params[key] === 'string') {
        urlSearchParams.append(key, params[key])
      } else if (typeof params[key] === 'number') {
        urlSearchParams.append(key, `${params[key]}`)
      } else if (Array.isArray(params[key])) {
        params[key].forEach((value) => {
          urlSearchParams.append(key, value)
        })
      }
    }
  }

  return urlSearchParams
}

// const normalizeRequest = (request: InstagramPostCreateRequest): InstagramPostCreateRequest => {
//   if (request.scheduledTime === '') {
//     delete request.scheduledTime
//   }

//   return request
// }

// export const toRequest = ({
//   media: medias,
//   product: productId,
//   scheduledTime,
//   postType,
//   social,
//   account,
//   ...rest
// }: Post): InstagramPostCreateRequest =>
//   normalizeRequest({
//     media: medias.map(({ id }) => ({ id })), // remove url
//     product: { id: productId, title: '' },
//     platformData: {
//       postType,
//       caption: 'description' in rest ? rest.description : '',
//       // hashtags: 'hashtags' in rest ? rest.hashtags.match(/#[a-z]+/gi) ?? [] : [],
//     },
//     socialAccount: {
//       id: social,
//       platform: 'INSTAGRAM',
//     },
//     scheduledTime,
//   })

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1)

// export const toForm = (post: PostResponse): Post => ({
//   media:
//     post.media?.reduce<MediaValue[]>((notEmptyMedia, { id, url }) => {
//       if (id !== undefined) {
//         notEmptyMedia.push({ id, url })
//       }
//       return notEmptyMedia
//     }, []) ?? [],
//   scheduledTime: post.scheduledTime,
//   postType: post.postType ?? ('POST' as any),
//   description: post.platformData?.caption ?? '',
//   hashtags: '',
//   product: post.product?.id ?? '',
//   account: post?.sourceAccount?.id ?? '',
//   social: post?.socialAccount?.id ?? '',
// })
