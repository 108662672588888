import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import { type FC, memo } from 'react'
import { TbEyeSearch } from 'react-icons/tb'

const PostsNotFound: FC = () => {
  return (
    <Sheet
      sx={{
        width: '100%',
        borderRadius: 'sm',
        p: 3,
        flexGrow: 1,
        mb: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <TbEyeSearch
        size={60}
        color="var(--joy-palette-neutral-400)"
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Typography sx={{ color: 'text.primary' }} level="h3">
        No posts found
      </Typography>
      <Typography sx={{ color: 'text.tertiary', mt: 0.5 }} fontWeight={500}>
        There’s no posts matching your search request
      </Typography>
    </Sheet>
  )
}

export default memo(PostsNotFound)
