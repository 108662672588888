import { createSelector } from '@reduxjs/toolkit'
import {
  type BaseQueryFn,
  type TypedUseQueryHookResult,
  type TypedUseQueryStateResult,
} from '@reduxjs/toolkit/query/react'
import { useMemo } from 'react'

import { useUserQuery } from '../store/endpoints/root'
import { type UserResponse, type UserSourceAccountResponse, type WithRequired } from '../types'

export type NotEmptySourceAccount = WithRequired<UserSourceAccountResponse, 'id' | 'platform'>

type UseUserQueryHookResult = TypedUseQueryHookResult<UserResponse, void, BaseQueryFn>

type UseSourceAccountsResult = Omit<UseUserQueryHookResult, 'data'> & {
  sourceAccounts?: NotEmptySourceAccount[]
}

const useSourceAccounts = (options?: Record<string, unknown>): UseSourceAccountsResult => {
  const selectSourceAccounts = useMemo(
    () =>
      createSelector(
        // for skip unnecessary renders, bc filter create new instance each time
        (result: TypedUseQueryStateResult<UserResponse, void, BaseQueryFn>) => result.data,
        (data?: UserResponse) =>
          data?.sourceAccounts?.filter(
            (sourceAccount): sourceAccount is NotEmptySourceAccount =>
              sourceAccount?.id !== undefined && sourceAccount?.platform !== undefined,
          ),
      ),
    [],
  )

  return useUserQuery(undefined, {
    selectFromResult: (result) => ({
      sourceAccounts: selectSourceAccounts(result),
      ...result,
    }),
  })
}

export default useSourceAccounts
