import Box from '@mui/joy/Box'
import { type FC } from 'react'

import { DatePipe, Picture } from '../../../../components/views'
import { isNotNullable } from '../../../../utils/typesHelper'
import { type PostRowProps } from '../../types'
import PostAccountContainer from '../PostAccountContainer/PostAccountContainer'
import PostTitle from '../PostTitle/PostTitle'

const DraftRow: FC<PostRowProps> = ({ post, onClick }) => {
  const firstMediaUrl = post?.media?.[0]?.url
  const socialAccountId = post?.socialAccount?.id
  const postType = post?.platformData?.postType

  return (
    <>
      <td style={{ cursor: 'pointer' }} onClick={onClick}>
        {isNotNullable(firstMediaUrl) && (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <Picture variant="plain" src={firstMediaUrl} />
          </Box>
        )}
      </td>
      <td style={{ cursor: 'pointer' }} onClick={onClick}>
        <PostTitle post={post} />
        {/* <Typography component="span">{post?.product?.title}</Typography>
        {isNotNullable(post?.platformData?.caption) && (
          <Typography component="span" textAlign="left">
            {post?.platformData?.caption}
          </Typography>
        )} */}
      </td>
      <td>{isNotNullable(post?.createdAt) && <DatePipe date={post.createdAt} />}</td>
      <td>
        <PostAccountContainer postType={postType} socialAccountId={socialAccountId} />
      </td>
    </>
  )
}

export default DraftRow
