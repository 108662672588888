export const AUDIOS = [
  {
    name: 'Friday Vibes',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/FridayVibes.mp3',
  },
  {
    name: 'Falling Down',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/FallingDown.mp3',
  },
  {
    name: 'Bright Clouds',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/BrightClouds.mp3',
  },
  {
    name: 'New Arcade',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/NewArcade.mp3',
  },
  {
    name: 'Under The Tree',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/UnderTheTree.mp3',
  },
  {
    name: 'Adversities',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/Adversities.mp3',
  },
  {
    name: 'Crossing The Line',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/CrossingTheLine.mp3',
  },
  {
    name: 'Luxury Dreams',
    url: 'https://cdn.amplorium.com/lottie/templates/audio/LuxuryDreams.mp3',
  },
]

export const MEDIAS = [
  'https://amplorium.s3.amazonaws.com/u/970702e573774c03bc31029ccc8db202/aa0103e106f04e5e9c9c1579605d382e.webp',
  'https://amplorium.s3.amazonaws.com/u/970702e573774c03bc31029ccc8db202/5e0cd5271b7c47ccba15ca4dbb31f16a.webp',
  'https://amplorium.s3.amazonaws.com/u/970702e573774c03bc31029ccc8db202/2895f3d698164fdba6d406d09a4fd893.jpg',
  'https://amplorium.s3.amazonaws.com/u/970702e573774c03bc31029ccc8db202/10966188daa84124ba6620b54182d873.jpg',
  'https://amplorium.s3.amazonaws.com/u/970702e573774c03bc31029ccc8db202/13d278cc3c154c9b9b7a7b5390be5a77.webp',
]

export const TEMPLATES = [
  {
    name: 'Template 1',
    thumb: 'https://cdn.amplorium.com/lottie/templates/thumbnails/amp-1.jpg',
    variants: [
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-1.1.json',
        images: 1,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-1.2.json',
        images: 2,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-1.3.json',
        images: 3,
      },
    ],
  },
  {
    name: 'Template 2',
    thumb: 'https://cdn.amplorium.com/lottie/templates/thumbnails/amp-2.jpg',
    variants: [
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-2.1.json',
        images: 1,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-2.2.json',
        images: 2,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-2.3.json',
        images: 3,
      },
    ],
  },
  {
    name: 'Template 3',
    thumb: 'https://cdn.amplorium.com/lottie/templates/thumbnails/amp-3.jpg',
    variants: [
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-3.1.json',
        images: 1,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-3.2.json',
        images: 2,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-3.3.json',
        images: 3,
      },
    ],
  },
  {
    name: 'Template 4',
    thumb: 'https://cdn.amplorium.com/lottie/templates/thumbnails/amp-4.jpg',
    variants: [
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-4.1.json',
        images: 1,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-4.2.json',
        images: 2,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-4.3.json',
        images: 3,
      },
    ],
  },
  {
    name: 'Template 5',
    thumb: 'https://cdn.amplorium.com/lottie/templates/thumbnails/amp-5.jpg',
    variants: [
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-5.1.json',
        images: 1,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-5.2.json',
        images: 2,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-5.3.json',
        images: 3,
      },
    ],
  },
  {
    name: 'Template 6',
    thumb: 'https://cdn.amplorium.com/lottie/templates/thumbnails/amp-6.jpg',
    variants: [
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-6.1.json',
        images: 1,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-6.2.json',
        images: 2,
      },
      {
        url: 'https://cdn.amplorium.com/lottie/templates/variants/amp-6.3.json',
        images: 3,
      },
    ],
  },
]
