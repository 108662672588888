import { type FC, useMemo } from 'react'

import { NavigationListWithHeader } from '../../../../components/views'
import { usePostsStatisticsQuery } from '../../../../store/endpoints/posts'
import { type OpenPostStatus, POST_STATUSES } from '../../../../types'
import { mapStatistic } from './utils'

const PostsStatusContainer: FC<{ activeStatus: OpenPostStatus }> = ({ activeStatus }) => {
  const { data: postsStatistics } = usePostsStatisticsQuery()

  const navigationList = useMemo(() => {
    const statuses = [
      {
        id: POST_STATUSES.SCHEDULED,
        navigationRoute: `?status=${POST_STATUSES.SCHEDULED}`,
        label: 'Scheduled',
      },
      {
        id: POST_STATUSES.SENT,
        navigationRoute: `?status=${POST_STATUSES.SENT}`,
        label: 'Published',
      },
      {
        id: POST_STATUSES.FAILED,
        navigationRoute: `?status=${POST_STATUSES.FAILED}`,
        label: 'Failed',
      },
      {
        id: POST_STATUSES.DRAFT,
        navigationRoute: `?status=${POST_STATUSES.DRAFT}`,
        label: 'Draft',
      },
    ]

    if (postsStatistics === undefined || postsStatistics === null) {
      return statuses
    }

    return mapStatistic(statuses, postsStatistics)
  }, [postsStatistics])

  return (
    <NavigationListWithHeader
      key="posts-statuses"
      header="Manual posts"
      items={navigationList}
      activeId={activeStatus}
    />
  )
}

export default PostsStatusContainer
