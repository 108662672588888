import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import DialogActions from '@mui/joy/DialogActions'
import DialogContent from '@mui/joy/DialogContent'
import Input from '@mui/joy/Input'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import Typography from '@mui/joy/Typography'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { TbArrowsMaximize, TbArrowsMinimize, TbSparkles, TbTransform } from 'react-icons/tb'

import {
  useAICreateMutation,
  useAIExpandMutation,
  useAIOptimizeMutation,
  useAIShortenMutation,
} from '../../../store/endpoints/ai'
import { type GenerateRequest } from '../../../types'

interface AIDescriptionToolsProps {
  productId?: string
  sourceAccountId: string
  productPlatform?: 'SHOPIFY'
  accountPlatform: 'INSTAGRAM' | 'FACEBOOK'
  description: string
  onChange: (newDescription: string) => void
}

const AIDescriptionTools = ({
  productId,
  sourceAccountId,
  productPlatform = 'SHOPIFY',
  accountPlatform,
  description,
  onChange,
}: AIDescriptionToolsProps): ReactElement => {
  const [create, { isLoading: isCreating }] = useAICreateMutation()

  const [optimize, { isLoading: isOptimizing }] = useAIOptimizeMutation()

  const [expand, { isLoading: isExpanding }] = useAIExpandMutation()

  const [shorten, { isLoading: isShorting }] = useAIShortenMutation()

  const [specialInstruction, setSpecialInstruction] = useState('')

  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = (): void => {
    setIsOpen((prevOpen) => !prevOpen)
  }

  const handleGenerateClick = async (): Promise<void> => {
    setIsOpen((prevOpen) => !prevOpen)

    const baseCreateRequest: GenerateRequest = {
      sourceAccount: {
        id: sourceAccountId,
        platform: 'SHOPIFY' as const,
      },
      platform: accountPlatform,
    }

    if (productId !== undefined) {
      baseCreateRequest.product = {
        id: productId,
      }
    }

    const request =
      specialInstruction.trim() !== ''
        ? { specialInstruction, ...baseCreateRequest }
        : baseCreateRequest

    const createResult = await create(request)

    if ('data' in createResult) {
      onChange(createResult.data.content ?? '')
    }
  }

  const handleShorter = async (): Promise<void> => {
    const shorterResult = await shorten({
      text: description,
      platform: accountPlatform,
    })

    if ('data' in shorterResult) {
      onChange(shorterResult.data.content ?? '')
    }
  }

  const handleExpand = async (): Promise<void> => {
    const expandResult = await expand({
      text: description,
      platform: accountPlatform,
    })

    if ('data' in expandResult) {
      onChange(expandResult.data.content ?? '')
    }
  }

  const handleOptimize = async (): Promise<void> => {
    const optimizeResult = await optimize({
      text: description,
      platform: accountPlatform,
    })

    if ('data' in optimizeResult) {
      onChange(optimizeResult.data.content ?? '')
    }
  }

  const isGenerateDisabled = productId === '' || sourceAccountId === ''

  const isOtherDisabled = description === '' || description === undefined

  return (
    <Box display="flex" gap={1}>
      <Button
        variant="outlined"
        size="sm"
        startDecorator={<TbSparkles />}
        loading={isCreating}
        onClick={handleToggle as () => void}
        disabled={isGenerateDisabled}
      >
        Generate
      </Button>
      <Button
        variant="outlined"
        size="sm"
        startDecorator={<TbTransform />}
        loading={isShorting}
        onClick={handleShorter as () => void}
        disabled={isOtherDisabled}
      >
        Simplify
      </Button>
      <Button
        variant="outlined"
        size="sm"
        startDecorator={<TbArrowsMaximize />}
        loading={isExpanding}
        onClick={handleExpand as () => void}
        disabled={isOtherDisabled}
      >
        Expand
      </Button>
      <Button
        variant="outlined"
        size="sm"
        startDecorator={<TbArrowsMinimize />}
        loading={isOptimizing}
        onClick={handleOptimize as () => void}
        disabled={isOtherDisabled}
      >
        Optimize
      </Button>
      <Modal open={isOpen} onClose={handleToggle}>
        <ModalDialog>
          <DialogContent>
            <Typography>Special instructions for AI (optional)</Typography>
            <Input
              value={specialInstruction}
              onChange={(changeEvent) => {
                setSpecialInstruction(changeEvent.target.value)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleGenerateClick}>Generate</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </Box>
  )
}

export default AIDescriptionTools
