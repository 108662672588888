import type { DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import { forwardRef } from 'react'

type ImageProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
  return <img ref={ref} {...props} />
})

Image.displayName = 'Image'

export default Image
