import { createContext, useContext } from 'react'

export enum CampaignStatus {
  Unknown = 'unknown',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Deleted = 'DELETED',
}

export const getCampaignStatus = (status?: string): CampaignStatus => {
  switch (status) {
    case CampaignStatus.Paused:
    case CampaignStatus.Deleted:
    case CampaignStatus.Active:
      return status
    default:
      return CampaignStatus.Unknown
  }
}

export const CampaignsStatusContext = createContext<CampaignStatus>(CampaignStatus.Unknown)

export default function useCampaignStatus(): CampaignStatus {
  return useContext(CampaignsStatusContext)
}
