import { useContext } from 'react'

import { TimeSchedulerContext } from './components/TimeSchedulerProvider/TimeSchedulerProvider'
import { type scheduledTime } from './useTimeScheduler.types'

const useTimeScheduler = (): scheduledTime => {
  const confirm = useContext(TimeSchedulerContext)

  if (confirm === undefined) {
    throw Error('TimeSchedulerContext should be provided')
  }

  return confirm
}

export default useTimeScheduler
