import { Alert, Snackbar } from '@mui/joy'
import { type FC } from 'react'
import { TbCircleCheck } from 'react-icons/tb'

import { type NotificationViewProps } from './types'

const SuccessSnackbar: FC<NotificationViewProps> = ({ notification, ...props }) => (
  <Snackbar color="success" variant="soft" sx={{ p: 0 }} {...props}>
    <Alert
      color="success"
      variant="solid"
      sx={{ width: '100%' }}
      startDecorator={<TbCircleCheck size={24} />}
    >
      {notification.message}
    </Alert>
  </Snackbar>
)

export default SuccessSnackbar
