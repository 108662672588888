import Skeleton from '@mui/joy/Skeleton'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'

const ActiveCampaignSkeletonRow: FC = () => {
  return (
    <tr>
      <td></td>
      <td>
        <Typography component="span">
          <Skeleton>Product Name: Super Product</Skeleton>
        </Typography>
        <Typography component="span" textAlign="left">
          <Skeleton>Title of product and some info: Super Title</Skeleton>
        </Typography>
      </td>
      <td>
        <Typography component="span">
          <Skeleton>20.12.2080</Skeleton>
        </Typography>
        <Typography component="span" textAlign="left">
          <Skeleton>23:59</Skeleton>
        </Typography>
      </td>
      <td>
        <Typography component="span">
          <Skeleton>20.12.2080</Skeleton>
        </Typography>
        <Typography component="span" textAlign="left">
          <Skeleton>23:59</Skeleton>
        </Typography>
      </td>
    </tr>
  )
}

export default ActiveCampaignSkeletonRow
