import { useCallback, useState } from 'react'
import { type ArrayPath, type FieldArray, useFieldArray } from 'react-hook-form'

import {
  type SocialAccountsForm,
  type SocialAccountsForms,
  type UseSocialAccountsFormsProps,
  type UseSocialAccountsFormsReturn,
} from './useSocialAccountsForms.types'

const useSocialAccountsForms = <T extends SocialAccountsForms<ArrayPath<T>>>({
  name,
  control,
  defaultSocialAccountId = '',
}: UseSocialAccountsFormsProps<T>): UseSocialAccountsFormsReturn<T> => {
  const [activeSocialAccountId, setActiveSocialAccountId] = useState(defaultSocialAccountId)

  const { append, remove, fields, ...rest } = useFieldArray<T>({
    control,
    name,
  })

  const appendForm = useCallback((socialAccount: SocialAccountsForm): void => {
    append(socialAccount as unknown as FieldArray<T, ArrayPath<T>>)
    setActiveSocialAccountId(socialAccount.socialAccountId)
  }, [])

  const removeForm = useCallback((socialAccountId: SocialAccountsForm['socialAccountId']): void => {
    const hasSomeForms = fields.length > 1

    const index = fields.findIndex(
      (field) => (field as unknown as SocialAccountsForm).socialAccountId === socialAccountId,
    )

    remove(index)

    if (hasSomeForms) {
      const activeIndex = index === 0 ? 1 : 0
      const activeForm = fields[activeIndex]

      setActiveSocialAccountId((activeForm as unknown as SocialAccountsForm).socialAccountId)
    }
  }, [])

  return {
    activeId: activeSocialAccountId,
    setActiveId: setActiveSocialAccountId,
    appendForm,
    removeForm,
    append,
    remove,
    fields,
    ...rest,
  }
}

export default useSocialAccountsForms
