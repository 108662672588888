import { Typography } from '@mui/joy'
import { type FC } from 'react'

import { isNotNullable } from '../../../utils/typesHelper'
import { type ErrorMessageProps } from './ErrorMessage.types'

const ErrorMessage: FC<ErrorMessageProps> = ({ error, children, sx, ...props }) => {
  console.log(error)
  if (isNotNullable(error)) {
    return (
      <Typography sx={{ mt: 1, color: 'danger.500', ...sx }} level="title-sm" {...props}>
        {error?.message}
        {children}
      </Typography>
    )
  }
  return null
}

export default ErrorMessage
