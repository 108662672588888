import Button from '@mui/joy/Button'
import Checkbox from '@mui/joy/Checkbox'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { TransparentForm } from '../../../../../../components/views/TransparentForm/TransparentForm'
import { type NewslettersFormProps, type NewslettersFormValues } from './NewslettersForm.type'

const NewslettersForm: FC<NewslettersFormProps> = ({ defaultValues, onSubmit }) => {
  const { control, formState, handleSubmit } = useForm<NewslettersFormValues>({
    defaultValues,
  })

  return (
    <TransparentForm onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="marketing"
        render={({ field: { value, ...field } }) => {
          return (
            <Checkbox
              checked={value}
              label={
                <>
                  <Typography fontWeight="md">Marketing emails</Typography>
                  <br />
                  Be up to date with Amplorium updates and promotions.
                </>
              }
              size="md"
              {...field}
            />
          )
        }}
      ></Controller>

      <Controller
        control={control}
        name="notification"
        render={({ field: { value, ...field } }) => {
          return (
            <Checkbox
              checked={value}
              label={
                <>
                  <Typography fontWeight="md">Product notifications</Typography>
                  <br />
                  Receive emails when any important activity in your account happens (post
                  publications, campaigns updates, etc.).
                </>
              }
              {...field}
            />
          )
        }}
      ></Controller>
      <Button type="submit" disabled={!formState.isDirty}>
        Save
      </Button>
    </TransparentForm>
  )
}

export default NewslettersForm
