import { type FC, useMemo } from 'react'

import { CAMPAIGN_STATUSES, type CampaignStatus } from '../../../../types'
import NavigationListWithHeader from '../../../../ui/NavigationListWithHeader/NavigationListWithHeader'

const CampaignsStatusesContainer: FC<{ activeStatusId: CampaignStatus }> = ({ activeStatusId }) => {
  const navigationList = useMemo(() => {
    const statuses = [
      {
        id: CAMPAIGN_STATUSES.ACTIVE,
        navigationRoute: `?status=${CAMPAIGN_STATUSES.ACTIVE}`,
        label: 'Active',
      },
      {
        id: CAMPAIGN_STATUSES.PAUSED,
        navigationRoute: `?status=${CAMPAIGN_STATUSES.PAUSED}`,
        label: 'Paused',
      },
    ]

    return statuses
  }, [])

  return (
    <NavigationListWithHeader
      key="campaigns-statuses"
      header="Campaigns"
      items={navigationList}
      activeId={activeStatusId}
    />
  )
}

export default CampaignsStatusesContainer
