import Alert, { type AlertProps } from '@mui/joy/Alert'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'

import { type SelectionType } from '../../../../types'

const SelectionTypeAlert: FC<AlertProps & { selectionType?: SelectionType }> = ({
  sx,
  selectionType = 'NEW_ITEM',
  ...props
}) => {
  return (
    <Alert sx={{ display: 'block', p: 1.5, ...sx }} {...props}>
      <Typography fontWeight={600}>Campaign details</Typography>
      <List sx={{ mt: 1 }} marker="disc">
        <ListItem>
          Will post the{' '}
          <Typography sx={{ display: 'inline-block' }} fontWeight={600}>
            {selectionType === 'NEW_ITEM' ? 'most recent' : 'random'}
          </Typography>{' '}
          product from a selected collection or tag
        </ListItem>
        <ListItem>Won’t post the same product again</ListItem>
        <ListItem>Newly added products become eligible for posting</ListItem>
        <ListItem>
          If no products meet the criteria, no posts will be made, and the system will check again
          during the next cycle
        </ListItem>
      </List>
    </Alert>
  )
}

export default SelectionTypeAlert
