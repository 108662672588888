import { type CampaignFormValues } from './components/CampaignForm/CampaignForm.types'

export interface CampaignFormSubmitterProps {
  campaigns: any[]
}

export enum CampaignsSubmitType {
  // Draft = 'draft',
  Instant = 'instant',
  Pause = 'pause',
  Remove = 'remove',
  Renew = 'renew',
  Update = 'update',
}

export interface CampaignsFormSubmitterProps {
  defaultCampaign?: Partial<CampaignFormValues>
}
