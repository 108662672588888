import { Checkbox, Select, type SelectProps } from '@mui/joy'
import Option from '@mui/joy/Option'
import { type FC } from 'react'

const days = [
  { value: 'SUNDAY', label: 'Sunday' },
  { value: 'MONDAY', label: 'Monday' },
  { value: 'TUESDAY', label: 'Tuesday' },
  { value: 'WEDNESDAY', label: 'Wednesday' },
  { value: 'THURSDAY', label: 'Thursday' },
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
]

const DaysSelect: FC<SelectProps<string, true>> = (props) => {
  return (
    <Select {...props} multiple>
      {days.map(({ value, label }) => (
        <Option key={value} value={value}>
          <Checkbox
            checked={props.value?.includes(value)}
            variant="outlined"
            size="md"
            color="neutral"
            sx={{ verticalAlign: 'text-bottom' }}
          />
          {label}
        </Option>
      ))}
    </Select>
  )
}

export default DaysSelect
