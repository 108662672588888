import Alert from '@mui/joy/Alert'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { TbInfoSquareRounded } from 'react-icons/tb'

import { type SubscriptionAlertProps } from './SubscriptionAlert.types'

const ExpiredSubscriptionAlert: FC<SubscriptionAlertProps> = ({ status, ...props }) => {
  return (
    <Alert variant="soft" color="danger" startDecorator={<TbInfoSquareRounded />} {...props}>
      <Typography>
        Your free trial has ended. Please consider upgrading to unlock all features!
      </Typography>
    </Alert>
  )
}

export default ExpiredSubscriptionAlert
