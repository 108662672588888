/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type { LottieMediaResponse, RegularMediaResponse } from '../../types'
import api from '../api'

const mediaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<LottieMediaResponse | RegularMediaResponse, FormData>({
      query: (body) => ({
        url: 'api/media/file',
        method: 'POST',
        body,
      }),
    }),
    uploadUrl: builder.mutation<LottieMediaResponse | RegularMediaResponse, { url: string }>({
      query: (body) => ({
        url: 'api/media/url',
        method: 'POST',
        body,
      }),
    }),
    uploadLottie: builder.mutation<
      LottieMediaResponse,
      {
        lottie: string
        thumbnail: string
        audioUrl?: string
      }
    >({
      query: (body) => ({
        url: 'api/media/lottie',
        method: 'POST',
        body,
      }),
    }),
    convertLottieToVideo: builder.mutation<any, any>({
      query: (body) => ({
        url: 'api/media/convert/lottie-to-video',
        method: 'POST',
        body,
      }),
    }),
    convertImageToVideo: builder.mutation<any, any>({
      query: (body) => ({
        url: 'api/media/convert/image-to-video',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useUploadFileMutation,
  useUploadUrlMutation,
  useConvertLottieToVideoMutation,
  useConvertImageToVideoMutation,
  useUploadLottieMutation,
} = mediaApi

export default mediaApi
