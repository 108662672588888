import { type CampaignStatus, type PaymentStatus, type PostStatus } from './types'

/**
 * The payment statuses
 * @constant
 */

export const PAYMENT_STATUSES: Record<string, PaymentStatus> = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  PAUSED: 'paused',
  TRIALING: 'trialing',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
}

/**
 * The post statuses
 * @constant
 */

export const POST_STATUSES: Record<PostStatus, PostStatus> = {
  CREATED: 'CREATED',
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  PUBLISHING: 'PUBLISHING',
  SENT: 'SENT',
  FAILED: 'FAILED',
}

/**
 * The campaign statuses
 * @constant
 */

export const CAMPAIGN_STATUSES: Record<CampaignStatus, CampaignStatus> = {
  ACTIVE: 'ACTIVE',
  PAUSED: 'PAUSED',
  DELETED: 'DELETED',
}
