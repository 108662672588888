import { type LottieVideoOptions } from '@amplorium/lottie-editor'
import Stack from '@mui/joy/Stack'
import { type ChangeEvent, type ReactElement, useState } from 'react'
import type { FieldValues } from 'react-hook-form'

import {
  useConvertLottieToVideoMutation,
  useUploadFileMutation,
  useUploadLottieMutation,
  useUploadUrlMutation,
} from '../../store/endpoints/media'
import { type MediaValue } from '../../types/types'
import { AttachedMedia } from './AttachedMedia'
import { MediaLoading } from './MediaLoading'
import { type MediaProps } from './types'
import { UploadInput } from './UploadInput'
import { useMediaController } from './useMediaController'
import { isUploadedPicture as isUploadedMedia, isVideo } from './utils'
import { VideoIconButton } from './VideoIconButton'

const Media = <T extends FieldValues>({
  name,
  control,
  pinnedMedia,
  videoEditorDisabled = false,
}: MediaProps<T>): ReactElement => {
  const {
    selectMedia,
    unselectedPinnedMedia = [],
    unselectMedia,
    selectedMedia,
    unselectedMedia,
    removeMedia,
  } = useMediaController({
    name,
    control,
    pinnedMedia,
  })

  const [loadingPinnedMedia, setLoadingPinnedMedia] = useState('')

  const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation()
  const [uploadUrl, { isLoading: isUrlUploading }] = useUploadUrlMutation()
  const [uploadLottie] = useUploadLottieMutation()
  const [convert, { isLoading: isConverting }] = useConvertLottieToVideoMutation()

  const isSelectedMedia = (media: Partial<MediaValue>): boolean =>
    selectedMedia.some((selectedMedia) => media?.frontId === selectedMedia?.frontId)

  const handleMediaClick = async (media: Partial<MediaValue>): Promise<void> => {
    if (isUploadedMedia(media)) {
      isSelectedMedia(media) ? unselectMedia(media) : selectMedia(media)
      return
    }

    if (!media.url) {
      return
    }

    if (media.frontId) {
      setLoadingPinnedMedia(media.frontId)
    }

    const result = await uploadUrl({ url: media.url })

    if ('data' in result && isUploadedMedia(result.data)) {
      setLoadingPinnedMedia('')
      selectMedia({ ...media, ...result.data, frontId: media?.frontId ?? result.data.id })
    }
  }

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = event.target.files
    if (files != null) {
      for (const file of Array.from(files)) {
        const formData = new FormData()
        formData.append('file', file)
        const result = await uploadFile(formData)
        if ('data' in result && isUploadedMedia(result.data)) {
          selectMedia({ ...result.data, frontId: result.data.id })
        }
      }
    }
  }

  const handleLottieUpload = async ({
    lottie,
    thumb,
    audio,
  }: LottieVideoOptions): Promise<void> => {
    if (typeof thumb === 'string' && lottie) {
      const response = await uploadLottie({
        lottie: JSON.stringify(lottie),
        thumbnail: thumb,
        audioUrl: audio ?? '',
      })

      if ('data' in response && typeof response.data.id !== 'undefined') {
        const video = await convert({ mediaId: response.data.id })
        if ('data' in video && isUploadedMedia(video.data)) {
          selectMedia({ ...video.data, frontId: video.data.id })
        }
      }
    }
  }

  const renderPicture = (media: MediaValue, index?: number): ReactElement | null => {
    return (
      <AttachedMedia
        key={media.frontId}
        name={media.frontId}
        index={index ?? 0}
        url={media.url}
        selected={isUploadedMedia(media) ? isSelectedMedia(media) : false}
        removable={!media?.isPinned}
        markAsError={videoEditorDisabled && !!media?.url && isVideo(media.url)}
        onRemovePicture={() => {
          removeMedia(media)
        }}
        onClick={async () => {
          await handleMediaClick(media)
        }}
      />
    )
  }

  const isNotLoadingPinnedMedia = (media: MediaValue): boolean =>
    loadingPinnedMedia !== media.frontId

  const allMediaUrls = [...unselectedPinnedMedia, ...selectedMedia, ...unselectedMedia]
    .map((media) => media.url)
    .filter((url): url is string => Boolean(url))
  const hasConvertedVideo = selectedMedia.some((media) => media.url && isVideo(media.url))
  const isVideoEditorVisible = !hasConvertedVideo && !isConverting && allMediaUrls.length !== 0

  return (
    <Stack gap={1.25} direction="row" flexWrap="wrap">
      {unselectedPinnedMedia?.filter(isNotLoadingPinnedMedia)?.map(renderPicture)}
      {(isFileUploading || isUrlUploading) && <MediaLoading />}
      {selectedMedia.map(renderPicture)}
      {unselectedMedia.map(renderPicture)}
      {!isFileUploading && <UploadInput onChange={handleInputChange} />}
      {isConverting && <MediaLoading />}
      {isVideoEditorVisible && (
        <VideoIconButton
          disabled={videoEditorDisabled}
          mediaUrls={allMediaUrls}
          onVideoUploaded={handleLottieUpload}
        />
      )}
    </Stack>
  )
}

export default Media
