import Button from '@mui/joy/Button'
import { type FC } from 'react'
import { TbCalendarEvent } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import Bar from '../../../../../../ui/Bar/Bar'
import { PostSubmitType } from '../../PostsFormContainer.types'
import { type PostsFormBarProps } from './PostsFormBar.types'

const PostsFormBar: FC<PostsFormBarProps> = ({ isSubmitting, ...props }) => {
  const navigate = useNavigate()

  const handleCancel = (): void => {
    navigate(-1)
  }

  return (
    <Bar key="posts-form-bar" {...props}>
      <Button variant="outlined" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        sx={{ ml: 'auto' }}
        type="submit"
        name={PostSubmitType.Draft}
        loading={isSubmitting}
        variant="outlined"
      >
        Save as Draft
      </Button>
      <Button type="submit" name={PostSubmitType.Instant} loading={isSubmitting}>
        Publish now
      </Button>
      <Button
        loading={isSubmitting}
        type="submit"
        name={PostSubmitType.Scheduled}
        startDecorator={<TbCalendarEvent />}
      >
        Schedule
      </Button>
    </Bar>
  )
}

export default PostsFormBar
