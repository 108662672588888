import Option from '@mui/joy/Option'
import Select, { type SelectOption, type SelectProps } from '@mui/joy/Select'
import type { ReactElement, ReactNode } from 'react'

import type { PostType } from '../../../types'

const postTypes = [
  { label: 'Any', value: 'ANY' },
  { label: 'Post', value: 'POST' as PostType },
  { label: 'Reel', value: 'REEL' as PostType },
  { label: 'Story', value: 'STORY' as PostType },
]

const renderValue = <Multiple extends boolean>(
  optionOrOptions: Multiple extends true
    ? Array<SelectOption<PostType | 'ANY'>>
    : SelectOption<PostType | 'ANY'> | null,
): ReactNode => (
  <>
    {`Post type is ${
      (Array.isArray(optionOrOptions)
        ? optionOrOptions?.map((option) => option.label?.toString())?.join(', ')
        : optionOrOptions?.label?.toString()) ?? 'ANY'
    }`}
  </>
)

const PostTypeSelect = <Multiple extends boolean = false>(
  props: SelectProps<PostType | 'ANY', Multiple>,
): ReactElement => {
  return (
    <Select renderValue={renderValue} {...props}>
      {postTypes.map(({ label, value }) => (
        <Option key={label} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  )
}

export default PostTypeSelect
