/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@mui/joy'
import Link from '@mui/joy/Link'
import Sheet from '@mui/joy/Sheet'
import Table from '@mui/joy/Table'
import Typography from '@mui/joy/Typography'
import { Skeleton } from '@mui/material'
import { type FC, useState } from 'react'

import { type PaymentHistoryResponse } from '../../../../../../types'

const Payments: FC<{ payments?: PaymentHistoryResponse[]; isLoading?: boolean }> = ({
  payments,
  isLoading = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const hasPayments = payments !== undefined && Array.isArray(payments) && payments.length > 0
  const slicedPayments = isCollapsed ? payments?.slice(0, 3) : payments

  return (
    <Sheet sx={{ width: 618, mt: 1 }}>
      {isLoading && <Skeleton variant="rectangular" sx={{ height: 208, borderRadius: 'sm' }} />}
      {!isLoading && (
        <Table aria-label="basic table" sx={{ height: 160 }}>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th style={{ width: '90px' }}>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {!hasPayments && (
              <tr>
                <td style={{ height: '40px', paddingBottom: '141px' }} colSpan={4}>
                  No payments yet
                </td>
              </tr>
            )}

            {slicedPayments?.map((item) => {
              if (item?.date === undefined) {
                return null
              }

              const date = new Date(item.date)

              return (
                item?.date !== undefined && (
                  <tr key={item.date}>
                    <td>
                      <Typography component="span" level="body-sm">
                        {date.toLocaleDateString()}
                      </Typography>
                    </td>
                    <td>{item.amount}</td>
                    <td>{item.status}</td>
                    <td>
                      <Link href={item.invoiceUrl}>PDF</Link>
                    </td>
                  </tr>
                )
              )
            })}
          </tbody>
        </Table>
      )}
      {hasPayments && (
        <Button
          sx={{ mt: 1 }}
          variant="soft"
          onClick={() => {
            setIsCollapsed((collapsed) => !collapsed)
          }}
        >
          {isCollapsed ? 'Show full' : 'Show less'}
        </Button>
      )}
    </Sheet>
  )
}

export default Payments
