import { useCallback, useState } from 'react'
import {
  type FieldValues,
  type Path,
  type PathValue,
  useController,
  useFormContext,
} from 'react-hook-form'

import { type Media, type MediaControllerProps, type UseMediaControllerReturn } from './types'

const addMediaFactory = (mediaToAdd: Media) => (currentMedia: Media[]) =>
  currentMedia ? [...currentMedia, mediaToAdd] : [mediaToAdd]

const removeMediaFactory = (mediaToRemove: Media) => (currentMedia: Media[]) =>
  currentMedia.filter(
    (currentMediaItem: Media) => currentMediaItem.frontId !== mediaToRemove.frontId,
  )

export const useMediaController = <T extends FieldValues>({
  name,
  control,
  pinnedMedia,
}: MediaControllerProps<T>): UseMediaControllerReturn => {
  const { setValue, getValues } = useFormContext<T>()
  const {
    field: { value: selectedMedia },
  } = useController<T>({ control, name })
  const [unselectedMedia, setUnselectedMedia] = useState<Media[]>([])

  const isSelectedMedia = useCallback(
    (media: Media): boolean =>
      selectedMedia.some((selectedMediaItem: Media) => media.frontId === selectedMediaItem.frontId),
    [selectedMedia],
  )

  const selectMedia = useCallback((mediaToSelect: Media): void => {
    const currentValue = getValues(name)
    const getNewMedia = addMediaFactory(mediaToSelect)
    setValue(name, getNewMedia(currentValue) as PathValue<T, Path<T>>)

    if (mediaToSelect?.isPinned) return

    setUnselectedMedia(removeMediaFactory(mediaToSelect))
  }, [])

  const unselectMedia = useCallback((mediaToUnselect: Media): void => {
    const currentValue = getValues(name)
    const getNewMedia = removeMediaFactory(mediaToUnselect)
    setValue(name, getNewMedia(currentValue) as PathValue<T, Path<T>>)

    if (mediaToUnselect?.isPinned) return

    setUnselectedMedia(addMediaFactory(mediaToUnselect))
  }, [])

  const removeMedia = useCallback((mediaToRemove: Media): void => {
    const currentValue = getValues(name)
    const getNewMedia = removeMediaFactory(mediaToRemove)
    setValue(name, getNewMedia(currentValue) as PathValue<T, Path<T>>)
    // onChange(removeMediaFactory(mediaToRemove))
    setUnselectedMedia(removeMediaFactory(mediaToRemove))
  }, [])

  const unselectedPinnedMedia = pinnedMedia?.filter((media) => !isSelectedMedia(media))

  return {
    selectMedia,
    unselectMedia,
    unselectedPinnedMedia,
    selectedMedia,
    unselectedMedia,
    removeMedia,
  }
}
