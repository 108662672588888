import { type FC } from 'react'

import { DatePipe, Picture } from '../../../../components/views'
import { type PostResponse } from '../../../../types'
import { isNotNullable } from '../../../../utils/typesHelper'
import PostAccountContainer from '../PostAccountContainer/PostAccountContainer'
import PostTitle from '../PostTitle/PostTitle'

const ScheduledRow: FC<{ post: PostResponse; onClick?: () => void }> = ({ post, onClick }) => {
  const firstMediaUrl = post?.media?.[0]?.url
  const socialAccountId = post?.socialAccount?.id
  const postType = post?.platformData?.postType

  return (
    <>
      <td style={{ cursor: 'pointer' }} onClick={onClick}>
        {isNotNullable(firstMediaUrl) && <Picture variant="plain" src={firstMediaUrl} />}
      </td>
      <td style={{ cursor: 'pointer' }} onClick={onClick}>
        <PostTitle post={post} />
      </td>
      <td>{isNotNullable(post?.createdAt) && <DatePipe date={post.createdAt} />}</td>
      <td>
        {isNotNullable(post?.scheduledTime) && <DatePipe date={post.scheduledTime}></DatePipe>}
      </td>
      <td>
        <PostAccountContainer postType={postType} socialAccountId={socialAccountId} />
      </td>
    </>
  )
}

export default ScheduledRow
