import Box from '@mui/joy/Box'
import { listItemButtonClasses } from '@mui/joy/ListItemButton'
import Typography from '@mui/joy/Typography'
import { type FC, memo } from 'react'

import NavigationList from '../NavigationList/NavigationList'
import { type NavigationListWithHeaderProps } from './types'

const NavigationListWithHeader: FC<NavigationListWithHeaderProps> = ({ header, ...props }) => {
  return (
    <Box
      sx={{
        width: 216,
        minHeight: 0,
        pt: 3,
        px: 2,
        pb: 3,
        overflow: 'hidden auto',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        [`& .${listItemButtonClasses.root}`]: { gap: 1.5 },
      }}
    >
      <Typography level="title-lg" sx={{ fontSize: 'xl', lineHeight: '31px' }}>
        {header}
      </Typography>
      <NavigationList {...props} />
    </Box>
  )
}

export default memo(NavigationListWithHeader)
