import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import type { ReactElement } from 'react'

import RegisterForm from './RegisterForm'

const RegisterPage = (): ReactElement => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <Paper sx={{ padding: '182px 120px' }}>
          <Typography variant="h2">Auth Illustration</Typography>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ padding: '60px 80px' }}>
          <Grid container justifyContent="center" spacing={1} flexDirection="column">
            <Grid item alignSelf="flex-end">
              <Typography variant="h6">Sign Up</Typography>
            </Grid>
            <Grid item>
              <RegisterForm />
            </Grid>
            <Grid item alignSelf="center">
              <Typography variant="caption">or</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" fullWidth>
                Continue with Shopify
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" fullWidth>
                Continue with Google
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" fullWidth>
                Continue with Facebook
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RegisterPage
