import { type NotEmptySocialAccount } from '../../../../hooks/useSocialAccounts'
import { type MediaValue } from '../../../../types'
import { type PostFormValues } from './components/PostsForm/components/PostForm/PostForm.types'

export interface SubmitOptions {
  isDraft?: boolean
  scheduledTime?: string
}

export interface PostsFormContainerProps {
  isNewPosts: boolean
  socialAccounts: NotEmptySocialAccount[]
  posts: Array<Partial<PostFormValues>>
}

export enum PostSubmitType {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Instant = 'instant',
}

export interface BackMediaValue extends MediaValue {
  id: string
}
