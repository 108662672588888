import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from '../../../../theme'

const PostPreviewThemeProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
}

export default PostPreviewThemeProvider
