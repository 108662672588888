import Box from '@mui/joy/Box'
import Skeleton from '@mui/joy/Skeleton'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'

const DraftSkeletonHeader: FC = () => {
  return (
    <>
      <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
        <Box display="flex" justifyContent="center">
          <Skeleton variant="rectangular" width={18} height={18} />
        </Box>
      </th>
      <th style={{ width: 48, padding: '12px 6px' }}></th>
      <th style={{ width: 970, padding: '12px 6px' }}>
        <Typography>
          <Skeleton>Title</Skeleton>
        </Typography>
      </th>
      <th style={{ width: 140, padding: '12px 6px' }}>
        <Typography>
          <Skeleton>Created</Skeleton>
        </Typography>
      </th>
      <th style={{ width: 220, padding: '12px 6px' }}>
        <Typography>
          <Skeleton>Page</Skeleton>
        </Typography>
      </th>
    </>
  )
}

export default DraftSkeletonHeader
