import Button from '@mui/joy/Button'
import Skeleton from '@mui/joy/Skeleton'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'

import HorizontalCardStack from '../../../ui/HorizontalCardStack/HorizontalCardStack'

const HorizontalCardSkeleton: FC = () => {
  return (
    <HorizontalCardStack
      icon={<Skeleton variant="circular" width={48} />}
      content={
        <Stack gap={1}>
          <Skeleton variant="rectangular" width={150} height={20}>
            <Typography>Some account name</Typography>
          </Skeleton>

          <Skeleton variant="rectangular" width={200} height={20}>
            <Typography>Skeleton text</Typography>
          </Skeleton>
        </Stack>
      }
      action={
        <Skeleton variant="rectangular" width={90} height={40}>
          <Button>Some button</Button>
        </Skeleton>
      }
    />
  )
}

export default HorizontalCardSkeleton
