import Typography, { type TypographyProps } from '@mui/joy/Typography'
import { type FC } from 'react'

import { type SubscriptionPlanResponse } from '../../../../../../types'

export type PlanPriceView = Pick<SubscriptionPlanResponse, 'description' | 'price' | 'period'>

export interface PlanPriceTypographyProps extends TypographyProps {
  plan: PlanPriceView
}

const PlanPriceTypography: FC<PlanPriceTypographyProps> = ({ plan, ...props }) => {
  return (
    <Typography fontWeight="md" {...props}>
      {plan.description}{' '}
      <Typography component="span" color="primary">
        {`$${plan?.price ?? 0}`}
      </Typography>
      {' /'}
      {plan.period}
    </Typography>
  )
}

export default PlanPriceTypography
