import { Skeleton, Typography } from '@mui/joy'
import { type FC } from 'react'

const BaseDatePipeSkeleton: FC = () => {
  return (
    <>
      <Typography component="span">
        <Skeleton>20.12.2080</Skeleton>
      </Typography>
      <Typography component="span" textAlign="left">
        <Skeleton>23:59</Skeleton>
      </Typography>
    </>
  )
}

export default BaseDatePipeSkeleton
