import { type BaseQueryFn, type TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react'

import { useUserQuery } from '../store/endpoints/root'
import { type UserResponse, type UserSocialAccountResponse, type WithRequired } from '../types'

type UseUserQueryHookResult = TypedUseQueryHookResult<UserResponse, void, BaseQueryFn>

type NotEmptySocialAccount = WithRequired<UserSocialAccountResponse, 'id'>

type UseSocialAccountResult = UseUserQueryHookResult & {
  socialAccount?: NotEmptySocialAccount
}

const useSocialAccount = (
  socialAccountId: string,
  options?: Record<string, unknown>,
): UseSocialAccountResult => {
  return useUserQuery(undefined, {
    selectFromResult: (result) => ({
      socialAccount: result?.data?.socialAccounts?.find(
        (socialAccount): socialAccount is NotEmptySocialAccount =>
          socialAccount.id === socialAccountId,
      ),
      ...result,
    }),
    ...options,
  })
}

export default useSocialAccount
