import Box from '@mui/joy/Box'
import Skeleton from '@mui/joy/Skeleton'
import Typography from '@mui/joy/Typography'
import { type FC, memo } from 'react'

import { ROWS_WIDTH } from '../../constants'

const BaseSkeletonHeader: FC = () => {
  return (
    <>
      <th style={{ width: ROWS_WIDTH.CHECKBOX, textAlign: 'center', padding: '12px 6px' }}>
        <Box display="flex" justifyContent="center">
          <Skeleton variant="rectangular" width={18} height={18} />
        </Box>
      </th>
      <th style={{ width: ROWS_WIDTH.PICTURE, padding: '12px 6px' }}></th>
      <th style={{ width: ROWS_WIDTH.TITLE, padding: '12px 6px' }}>
        <Typography>
          <Skeleton>Title</Skeleton>
        </Typography>
      </th>
      <th style={{ width: ROWS_WIDTH.CREATED_ON, padding: '12px 6px' }}>
        <Typography>
          <Skeleton>Created</Skeleton>
        </Typography>
      </th>
      <th style={{ width: ROWS_WIDTH.PUBLISHED, padding: '12px 6px' }}>
        <Typography>
          <Skeleton>Published</Skeleton>
        </Typography>
      </th>
      <th style={{ width: ROWS_WIDTH.PAGE, padding: '12px 6px' }}>
        <Typography>
          <Skeleton>Page</Skeleton>
        </Typography>
      </th>
    </>
  )
}

export default memo(BaseSkeletonHeader)
