import Box from '@mui/joy/Box'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import Option from '@mui/joy/Option'
import Select, { type SelectProps } from '@mui/joy/Select'
import { type FC } from 'react'

import useSocialAccounts from '../../../hooks/useSocialAccounts'
import { Spinner } from '../../../ui'
import SocialAccountAvatar from '../../views/SocialAccountAvatar/SocialAccountAvatar'

const SocialAccountSelectV2: FC<SelectProps<string, false>> = ({ children, ...props }) => {
  const { socialAccounts, isLoading, isFetching } = useSocialAccounts()

  return (
    <Select {...props}>
      {children}

      {(isLoading || isFetching) && (
        <Option value="loading" disabled>
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Spinner size="sm" />
          </Box>
        </Option>
      )}

      {socialAccounts?.map((socialAccount) => (
        <Option key={socialAccount.id} value={socialAccount.id}>
          <ListItemDecorator>
            <SocialAccountAvatar size="sm" socialAccount={socialAccount} />
          </ListItemDecorator>

          {socialAccount.name}
        </Option>
      ))}
    </Select>
  )
}

export default SocialAccountSelectV2
