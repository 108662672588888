import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import { type FC, memo } from 'react'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'

interface PaginationProps {
  page: number
  count?: number
  onPageChange: (newPage: number) => void
}

const Pagination: FC<PaginationProps> = ({ page, count = 1, onPageChange }) => {
  const pages = Array(count)
    .fill(null)
    .map((_, i) => i + 1)

  const createPageButtonClickHandler = (page: number) => (): void => {
    onPageChange(page)
  }

  const handlePrevClick = (): void => {
    onPageChange(page - 1)
  }

  const handleNextClick = (): void => {
    onPageChange(page + 1)
  }

  return (
    <Box
      className="Pagination-laptopUp"
      sx={{
        pt: 1,
        gap: 1,
        display: {
          md: 'flex',
        },
      }}
    >
      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        disabled={page === 1}
        onClick={handlePrevClick}
      >
        <IoChevronBack />
      </IconButton>

      {pages.map((currentPage) => (
        <IconButton
          key={currentPage}
          size="sm"
          variant={currentPage === page ? 'solid' : 'outlined'}
          color="neutral"
          onClick={createPageButtonClickHandler(currentPage)}
        >
          {currentPage}
        </IconButton>
      ))}

      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        disabled={page === count}
        onClick={handleNextClick}
      >
        <IoChevronForward />
      </IconButton>
    </Box>
  )
}

export default memo(Pagination)
