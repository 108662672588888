import { type NavigationListItem } from '../../../../ui/NavigationList/types'

export function mapStatistic(
  items: NavigationListItem[],
  statisticMap: Record<string, number | undefined>,
): NavigationListItem[] {
  return items.map((item: NavigationListItem): NavigationListItem => {
    const itemsCount = statisticMap[item.id]

    return Number.isFinite(itemsCount) ? { itemsCount, ...item } : item
  })
}
