import { type BaseSyntheticEvent, type FC, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import CampaignPostPreviewContainer from '../../components/forms/CampaignPostPreviewContainer/CampaignPostPreviewContainer'
import useConfirm from '../../hooks/useConfirm/useConfirm'
import {
  useCreateCampaignMutation,
  useEditCampaignMutation,
  useEditCampaignStatusMutation,
} from '../../store/endpoints/campaign'
import {
  type CampaignsFormSubmitterProps,
  CampaignsSubmitType,
} from './CampaignsFormSubmitter.type'
import { getSubmitType, toCampaign } from './CampaignsFormSubmitter.utils'
import CampaignsForm from './components/CampaignsForm/CampaignsForm'
import { type CampaignsFormValues } from './components/CampaignsForm/CampaignsForm.types'

/**
 * CampaignForContainer use RTK generated hook for Submit form
 */
const CampaignsFormSubmitter: FC<CampaignsFormSubmitterProps> = ({ defaultCampaign }) => {
  const [createCampaign] = useCreateCampaignMutation()
  const [editCampaign] = useEditCampaignMutation()
  const [editCampaignStatus] = useEditCampaignStatusMutation()

  const navigate = useNavigate()

  const { id } = useParams<{ id: string }>()

  const confirm = useConfirm()

  const handleSubmit = async (
    { campaigns }: CampaignsFormValues,
    event?: BaseSyntheticEvent,
  ): Promise<void> => {
    event?.stopPropagation()
    const submitType = getSubmitType(event)
    const status = submitType === CampaignsSubmitType.Pause ? 'PAUSED' : 'ACTIVE'

    let results

    switch (submitType) {
      case CampaignsSubmitType.Remove:
        {
          if (id === undefined) {
            return
          }

          const confirmed = await confirm({
            question: 'Campaign will be removed',
            confirm: 'Remove',
          })

          if (!confirmed) {
            return
          }

          results = [await editCampaignStatus({ campaignId: id, status: 'DELETED' })]
        }
        break
      case CampaignsSubmitType.Renew:
      case CampaignsSubmitType.Pause:
        if (id === undefined) {
          return
        }

        results = [await editCampaignStatus({ campaignId: id, status })]
        break
      case CampaignsSubmitType.Update:
        if (id === undefined) {
          return
        }

        results = await Promise.allSettled(
          campaigns.map(async (campaignFormValue): Promise<unknown> => {
            return await editCampaign({ id, ...toCampaign(campaignFormValue) })
          }),
        )
        break
      default:
        results = await Promise.allSettled(
          campaigns.map(async (campaignFormValue): Promise<unknown> => {
            return await createCampaign(toCampaign(campaignFormValue))
          }),
        )
    }

    const hasSubmitError = results.some((result) => 'error' in result)

    if (hasSubmitError) {
      console.error(results)

      return
    }

    navigate(`/campaigns?status=${status}`)
  }

  const defaultValues = useMemo(
    () => ({ campaigns: defaultCampaign === undefined ? [] : [defaultCampaign] }),
    [defaultCampaign],
  )

  return (
    <CampaignsForm
      defaultValues={defaultValues}
      renderPreview={(values) => (
        <CampaignPostPreviewContainer
          sourceAccountId={values.sourceAccountId}
          socialType={values.socialName}
          socialAccountId={values.socialAccountId}
          postType={values.postType}
          mediaRule={values.mediaRule}
          description={values.postTemplates[0]}
          collectionIds={values.collectionIds ?? []}
        />
      )}
      onSubmit={handleSubmit}
    />
  )
}

export default CampaignsFormSubmitter
