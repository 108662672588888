import { type FC } from 'react'

import { usePaymentsQuery } from '../../../../store/endpoints/payment'
import Payments from './components/Payments/Payments'

const PaymentsHistoryContainer: FC = () => {
  const { data: payments, isLoading } = usePaymentsQuery()

  return <Payments payments={payments} isLoading={isLoading} />
}

export default PaymentsHistoryContainer
