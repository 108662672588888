import type { FC, ReactElement, SVGProps } from 'react'

import { ReactComponent as SpinnerSvg } from '../../assets/img/spinner.svg'

export interface SpinnerProps extends SVGProps<SVGSVGElement> {
  size?: 'md' | 'lg' | 'xs' | 'sm'
}

const Spinner: FC<SpinnerProps> = ({ size, style, ...props }): ReactElement => {
  let height

  switch (size) {
    case 'xs':
      height = '18px'
      break
    case 'sm':
      height = '28px'
      break
    case 'md':
      height = '36px'
      break
    case 'lg':
    default:
      height = '42px'
      break
  }

  return <SpinnerSvg style={{ height, ...style }} {...props} />
}

export default Spinner
