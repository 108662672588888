import Button from '@mui/joy/Button'
import Card from '@mui/joy/Card'
import CardActions from '@mui/joy/CardActions'
import CardContent from '@mui/joy/CardContent'
import Modal, { type ModalProps } from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'

import { type ConfirmationOptions } from '../../useConfirm.types'

export interface ConfirmationModalProps extends Omit<ModalProps, 'children'> {
  options?: ConfirmationOptions
  onConfirm: () => void
  onDecline: () => void
}

const DEFAULT_CONFIRM_OPTIONS = {
  title: 'Please confirm action',
  question: '',
  cancel: 'Cancel',
  confirm: 'Confirm',
}

export const ConfirmModal: FC<ConfirmationModalProps> = ({
  options = DEFAULT_CONFIRM_OPTIONS,
  onConfirm,
  onDecline,
  sx,
  ...props
}) => {
  const {
    title = DEFAULT_CONFIRM_OPTIONS.title,
    question = DEFAULT_CONFIRM_OPTIONS.question,
    cancel = DEFAULT_CONFIRM_OPTIONS.cancel,
    confirm = DEFAULT_CONFIRM_OPTIONS.confirm,
  } = options

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...props}
    >
      <Card
        sx={{
          width: 346,
          display: 'flex',
          alignItems: 'flex-start',
          padding: 2.5,
        }}
      >
        <ModalClose />
        <CardContent>
          <Typography component="h4" level="title-md" fontWeight="lg" sx={{ textAlign: 'left' }}>
            {title}
          </Typography>
          <Typography level="body-sm" sx={{ textAlign: 'left' }}>
            {question}
          </Typography>
        </CardContent>
        <CardActions sx={{ gap: 2, width: '100%', justifyContent: 'flex-end' }}>
          <Button size="sm" variant="outlined" onClick={onDecline}>
            {cancel}
          </Button>
          <Button size="sm" color="warning" onClick={onConfirm}>
            {confirm}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  )
}
