import { type ReactElement, useEffect, useState } from 'react'
import { type FieldValues } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

import { useProductQuery } from '../../store/endpoints/shopify'
import { type MediaValue } from '../../types'
import Media from './Media'
import { type MediaWithProductProps } from './types'

export const MediaWithProduct = <T extends FieldValues>({
  productId,
  sourceAccountId,
  ...props
}: MediaWithProductProps<T>): ReactElement => {
  const hasProductParams = Boolean(sourceAccountId && productId)
  const [productMedia, setProductMedia] = useState<MediaValue[]>([])
  const { data: product, isSuccess: isProductQuerySuccess } = useProductQuery(
    { sourceAccountId: sourceAccountId ?? '', productId: productId ?? '' },
    {
      skip: !hasProductParams,
    },
  )

  useEffect(() => {
    if (product && Array.isArray(product.images)) {
      const productMedia = product.images.map((url) => ({
        url,
        frontId: uuidv4(),
        isPinned: true,
      }))

      setProductMedia(productMedia)
    }
  }, [product])

  if (hasProductParams && isProductQuerySuccess) {
    return <Media pinnedMedia={productMedia} {...props} />
  }

  return <Media {...props} />
}
