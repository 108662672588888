import {
  PAYMENT_STATUSES,
  type PaymentStatus,
  type SubscriptionPlanGroupResponse,
  type SubscriptionResponse,
} from '../../../../types'
import { type Plan } from './components/PaymentPlans/PaymentPlans.type'
import { type BillingTextOptions } from './PaymentPlansContainer.type'

/**
 * @param plansAndGroup - returns by api dto
 * @param subscription - returns by api dto
 * @returns return ready to render Plan interface
 */
export const getPlans = (
  plansAndGroup?: SubscriptionPlanGroupResponse | undefined,
  subscription?: SubscriptionResponse | undefined,
): Plan[] | undefined => {
  const plans = plansAndGroup?.plans

  if (!Array.isArray(plans)) return

  return plans
    .filter((plan): plan is Plan => plan?.id !== undefined)
    .map((plan) => {
      const isActive = plan.id === subscription?.planId && Boolean(subscription?.active)
      const switchText = getSwitchText(
        plan.period,
        subscription?.status as PaymentStatus,
        subscription?.planId !== undefined,
      )
      const billingText = getBillingText({
        period: plan.period,
        expiresAt:
          subscription?.expiresAt !== undefined ? new Date(subscription?.expiresAt) : undefined,
        hasAutoPayNextMonth: subscription?.status !== 'cancel',
      })

      const expandedPlan = { ...plan, isActive, billingText, switchText }

      if (isActive) {
        expandedPlan.status = subscription?.status as PaymentStatus
      }

      return expandedPlan
    })
}

export const getBillingText = ({
  period,
  expiresAt,
  hasAutoPayNextMonth,
}: BillingTextOptions): string => {
  if (expiresAt === undefined) {
    if (period === undefined) return ''

    return getUnsubscribedBillingText(period)
  }

  return getSubscribedBillingText(expiresAt, hasAutoPayNextMonth)
}

export const getUnsubscribedBillingText = (period: string): string => {
  switch (period) {
    case 'month':
      return 'Includes all Pro features bills monthly'
    case 'year':
      return 'Includes all Pro features bills yearly'
    default:
      return ''
  }
}

export const getSubscribedBillingText = (expiresAt: Date, hasAutoPayNextMonth = false): string => {
  if (Number.isNaN(Number(expiresAt))) return ''

  const text = hasAutoPayNextMonth ? 'Next billing cycle ' : 'Your subscription ends on '
  const dateString = expiresAt.toLocaleDateString('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  })

  return text + dateString
}

export const getSwitchText = (
  period?: string,
  status = PAYMENT_STATUSES.TRIALING,
  hasSubscription = false,
): string => {
  if (!hasSubscription) return 'Upgrade'

  switch (status) {
    case PAYMENT_STATUSES.CANCELED:
      return 'Renew'
    default:
      switch (period) {
        case 'month':
          return 'Change to monthly'
        case 'year':
          return 'Change to yearly'
        default:
          return 'Upgrade'
      }
  }
}
