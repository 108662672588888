/* eslint-disable @typescript-eslint/ban-types */
import { combineReducers, configureStore, type Middleware } from '@reduxjs/toolkit'
import { type CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'

import api from './api'
import authReducer from './auth/authSlice'
import campaignsReducer from './campaigns/campaignsSlice'
import notificationsReducer from './notifications/notificationsSlice'
import postsReducer from './posts/postsSlice'

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  campaigns: campaignsReducer,
  notifications: notificationsReducer,
  posts: postsReducer,
})

const createMiddlewares = (
  getDefaultMiddleware: CurriedGetDefaultMiddleware<any>,
): ReadonlyArray<Middleware<{}, any>> => getDefaultMiddleware().concat(api.middleware)

export const store = configureStore({
  reducer: rootReducer,
  // devTools: process.env.NODE_ENV === 'development',
  middleware: createMiddlewares,
})

export const setupStore = (
  preloadedState?: Partial<RootState>,
): ReturnType<typeof configureStore> =>
  configureStore({
    reducer: rootReducer,
    middleware: createMiddlewares,
    preloadedState,
  })

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppStore = ReturnType<typeof setupStore>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
