import Avatar, { type AvatarProps } from '@mui/joy/Avatar'
import { type FC } from 'react'

import { type UserSocialAccountResponse } from '../../../types'
import { Spinner } from '../../../ui'
import RightBottomBadge from '../../../ui/RightBottomBadge/RightBottomBadge'
import SocialAvatar from '../SocialAvatar/SocialAvatar'

interface SocialAccountAvatarProps extends AvatarProps {
  socialAccount: UserSocialAccountResponse
  isLoading?: boolean
}

const SocialAccountAvatar: FC<SocialAccountAvatarProps> = ({
  socialAccount,
  isLoading,
  ...props
}) => {
  // const { socialAccount, isLoading } = useSocialAccount(socialAccountId)

  const socialName = socialAccount?.platform

  const socialAvatar = socialAccount?.picture

  const avatar = isLoading === true ? <Spinner /> : <Avatar src={socialAvatar} {...props}></Avatar>

  if (socialName === undefined) {
    return avatar
  }

  return (
    <RightBottomBadge
      badgeContent={
        <SocialAvatar
          // size="xs"
          sx={{
            '--Avatar-radius': 'var(--joy-radius-sm)',
            '--Avatar-size': '16px',
          }}
          socialName={socialName}
        />
      }
    >
      {avatar}
    </RightBottomBadge>
  )
}

export default SocialAccountAvatar
