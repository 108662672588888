import { type BaseSyntheticEvent, type FC, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import PostPostPreviewContainer from '../../../../components/forms/PostPostPreviewContainer/PostPostPreviewContainer'
import useTimeScheduler from '../../../../hooks/useTimeScheduler/useTimeScheduler'
import { type AppDispatch } from '../../../../store'
import {
  useCreateFacebookPostMutation,
  useCreateInstagramPostMutation,
  useEditFacebookPostMutation,
  useEditInstagramPostMutation,
} from '../../../../store/endpoints/posts'
import { trackId } from '../../../../store/posts/actions'
import { type PostFormValues } from './components/PostsForm/components/PostForm/PostForm.types'
import PostsForm from './components/PostsForm/PostsForm'
import { type PostsFormValues } from './components/PostsForm/PostsForm.types'
import { useEnrichText } from './PostFormContainer.hooks'
import { getSubmitType } from './PostFormContainer.utils'
import {
  type BackMediaValue,
  type PostsFormContainerProps,
  type SubmitOptions,
} from './PostsFormContainer.types'

const PostsFormContainer: FC<PostsFormContainerProps> = ({ isNewPosts, socialAccounts, posts }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const scheduleTime = useTimeScheduler()

  const [createInstagramPost] = useCreateInstagramPostMutation()
  const [createFacebookPost] = useCreateFacebookPostMutation()
  const [editInstagramPost] = useEditInstagramPostMutation()
  const [editFacebookPost] = useEditFacebookPostMutation()
  const { enrichText } = useEnrichText()

  const defaultValues = useMemo(() => ({ posts }), [posts])
  const submitters = isNewPosts
    ? {
        FACEBOOK: createFacebookPost,
        INSTAGRAM: createInstagramPost,
      }
    : {
        FACEBOOK: editFacebookPost,
        INSTAGRAM: editInstagramPost,
      }

  const submitPost = async (
    postFormValues: Required<PostFormValues>,
    { isDraft, scheduledTime }: SubmitOptions,
  ): Promise<void> => {
    const product =
      postFormValues.productId !== undefined ? { product: { id: postFormValues.productId } } : {}

    const richCaption = await enrichText(postFormValues.caption ?? '', postFormValues.productId)

    const result = await submitters[postFormValues.socialName]({
      draft: isDraft,
      body: {
        id: postFormValues.postId,
        sourceAccount: {
          id: postFormValues.sourceAccountId,
          platform: 'SHOPIFY',
        },
        socialAccount: {
          id: postFormValues.socialAccountId,
          platform: postFormValues.socialName,
        },
        platformData: {
          postType: postFormValues.postType,
          caption: richCaption,
        },
        media: postFormValues.media.filter(
          (media): media is BackMediaValue => media.id !== undefined,
        ),
        scheduledTime,
        ...product,
      },
    })

    if ('error' in result && 'data' in result.error) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw result.error
    }
    if ('data' in result && result.data.id) {
      dispatch(trackId(result.data?.id))
    }
  }

  const submitPosts = async (
    posts: PostFormValues[],
    { isDraft = false, scheduledTime }: SubmitOptions,
  ): Promise<void> => {
    try {
      await Promise.all(
        posts.map(async (postFormValues: PostFormValues) => {
          await submitPost(postFormValues as Required<PostFormValues>, { isDraft, scheduledTime })
        }),
      )

      navigate(
        `posts?status=${isDraft ? 'DRAFT' : scheduledTime === undefined ? 'SENT' : 'SCHEDULED'}`,
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (
    { posts }: PostsFormValues,
    event?: BaseSyntheticEvent,
  ): Promise<void> => {
    event?.stopPropagation()
    const submitType = getSubmitType(event)
    const isDraft = submitType === 'draft'
    if (submitType !== 'scheduled') {
      await submitPosts(posts, { isDraft })
      return
    }

    const scheduledTime = await scheduleTime()

    await submitPosts(posts, { isDraft, scheduledTime })
  }

  return (
    <PostsForm
      socialAccounts={socialAccounts}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      renderPostFormPreview={(values) => (
        <PostPostPreviewContainer
          socialAccountId={values.socialAccountId ?? ''}
          postType={values.postType ?? 'POST'}
          socialType={values.socialName ?? 'INSTAGRAM'}
          media={values.media ?? []}
          sourceAccountId={values.sourceAccountId}
          productId={values.productId}
          description={values.caption ?? ''}
        />
      )}
    />
  )
}

export default PostsFormContainer
