import 'moment-timezone'

import { Box, Button } from '@mui/joy'
import Card from '@mui/joy/Card'
import CardActions from '@mui/joy/CardActions'
import CardContent from '@mui/joy/CardContent'
import Modal, { type ModalProps } from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import { StaticDatePicker, TimeField } from '@mui/x-date-pickers'
import moment, { type Moment } from 'moment'
import { type FC, useRef } from 'react'

export interface TimeSchedulerModalProps extends Omit<ModalProps, 'children'> {
  onTimeConfirmed: (date: string) => void
  onDecline: () => void
}

export const TimeSchedulerModal: FC<TimeSchedulerModalProps> = ({
  onTimeConfirmed,
  onDecline,
  sx,
  ...props
}) => {
  const value = useRef<Moment>(moment.tz('America/New_York'))
  // const [value, setValue] = useState<Moment>(moment.tz('America/New_York'))

  const handleConfirm = (): void => {
    onTimeConfirmed(value.current.format('YYYY-MM-DDTHH:mm:ss'))
  }

  return (
    <Modal
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...props}
    >
      <Card
        sx={{
          width: 360,
          display: 'flex',
          alignItems: 'flex-start',
          padding: 2.5,
        }}
      >
        <ModalClose />
        <CardContent sx={{ mt: 3 }}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <StaticDatePicker
              value={value.current}
              onChange={(a) => {
                if (a !== null) {
                  value.current = a as any
                }
              }}
              showDaysOutsideCurrentMonth
              displayStaticWrapperAs="desktop"
            />
            <TimeField
              value={value.current}
              onChange={(a) => {
                if (a !== null) {
                  value.current = a as any
                }
              }}
              sx={{ marginTop: 3 }}
              fullWidth
              size="small"
            />
          </Box>
        </CardContent>
        <CardActions sx={{ gap: 2, width: '100%', justifyContent: 'flex-end' }}>
          <Button size="sm" variant="outlined" onClick={onDecline}>
            Cancel
          </Button>
          <Button size="sm" onClick={handleConfirm}>
            Confirm
          </Button>
        </CardActions>
      </Card>
    </Modal>
  )
}
