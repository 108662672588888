import Alert from '@mui/joy/Alert'
import Button from '@mui/joy/Button'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import { type FC } from 'react'
import { TbCheck } from 'react-icons/tb'

import { type SubscriptionPlanResponse } from '../../../../../../types'
import PlanCard from '../PlanCard/PlanCard'
import { type PaymentPlansProps } from './PaymentPlans.type'

const PaymentPlans: FC<PaymentPlansProps> = ({
  plans,
  loading,
  onPlanUpgrade,
  onPlanCancel,
  ...props
}) => {
  const createCancelHandler = (plan: SubscriptionPlanResponse) => (): void => {
    onPlanCancel?.(plan)
  }

  const createUpgradeHandler = (plan: SubscriptionPlanResponse) => (): void => {
    onPlanUpgrade(plan)
  }

  return (
    <List {...props}>
      {plans.map((plan) => {
        return (
          <ListItem key={plan.id}>
            <PlanCard
              sx={{ width: 568 }}
              plan={plan}
              actions={
                <>
                  {plan.isActive === true && (
                    <Alert color="success" size="sm" startDecorator={<TbCheck />} variant="plain">
                      Active
                    </Alert>
                  )}
                  {plan.isActive === true ? (
                    <Button loading={loading} onClick={createCancelHandler(plan)}>
                      Cancel
                    </Button>
                  ) : (
                    <Button loading={loading} onClick={createUpgradeHandler(plan)}>
                      {plan.switchText}
                    </Button>
                  )}
                </>
              }
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default PaymentPlans
