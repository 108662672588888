import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import DialogActions from '@mui/joy/DialogActions'
import DialogContent from '@mui/joy/DialogContent'
import Link from '@mui/joy/Link'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Sheet from '@mui/joy/Sheet'
import { type FC, useState } from 'react'
import { TbExternalLink } from 'react-icons/tb'

import PostPreview from '../../../../components/views/PostPreview/PostPreview'
import { useAppDispatch } from '../../../../store'
import { pushErrorNotification } from '../../../../store/notifications/actions'
import { type PostResponse } from '../../../../types'
import { type PostTableProps } from '../../types'
import BasePostsTable from '../base/BasePostsTable'
import { toPostPreview } from './utils'

const SentPostsTable: FC<PostTableProps> = (props) => {
  const [post, setPost] = useState<PostResponse | null>(null)
  const dispatch = useAppDispatch()

  const handleClosePostPreview = (): void => {
    setPost(null)
  }

  const handleRowClick = (post: PostResponse): void => {
    if (post) {
      setPost(post)
    } else {
      dispatch(pushErrorNotification({ message: "Can't open post, post link is empty" }))
    }
  }
  return (
    <>
      <BasePostsTable {...props} onRowClick={handleRowClick} />
      <Modal open={!!post} onClose={handleClosePostPreview}>
        <ModalDialog variant="plain">
          <Box display="flex">
            <ModalClose />
          </Box>
          <DialogContent sx={{ position: 'relative' }}>
            {post && <PostPreview post={toPostPreview(post)} />}
            <DialogActions>
              <Sheet
                sx={{
                  p: 2,
                  width: '100%',
                  borderRadius: 'sm',
                  background: 'var(--background-level1)',
                  overflow: 'hidden',
                }}
                variant="outlined"
              >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Button variant="outlined" onClick={handleClosePostPreview}>
                    Cancel
                  </Button>
                  <Link href={post?.postLink} target="_blank" underline="none">
                    <Button startDecorator={<TbExternalLink />} content="a" href={post?.postLink}>
                      Visit post
                    </Button>
                  </Link>
                </Box>
              </Sheet>
            </DialogActions>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  )
}

export default SentPostsTable
