import { type BoxProps } from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { TbArrowBackUp } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { Bar } from '../../../../components/views'
import useIsNewCampaignFlag from '../../hooks/useIsNewCampaignFlag'

const CampaignNavigation: FC<{ campaignName?: string } & BoxProps> = ({
  campaignName = '',
  children,
  ...props
}) => {
  const navigate = useNavigate()
  const isNewCampaign = useIsNewCampaignFlag()

  const handleBackClick = (): void => {
    navigate(-1)
  }
  const head = isNewCampaign ? 'New campaign' : campaignName

  return (
    <Bar sx={{ gap: 2 }} {...props}>
      <IconButton
        sx={{ color: 'text.icon' }}
        size="md"
        variant="outlined"
        onClick={handleBackClick}
      >
        <TbArrowBackUp />
      </IconButton>
      <Typography sx={{ fontSize: 'xl' }} level="title-lg">
        {head}
      </Typography>
      {children}
    </Bar>
  )
}

export default CampaignNavigation
