/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type { CollectionsResponse, ProductByIdResponse, ProductsResponse } from '../../types'
import api from '../api'

const shopifyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    products: builder.query<ProductsResponse[], { account: string; title?: string }>({
      query: ({ account, title }) =>
        `api/shopify/${account}/products${title !== undefined ? '?title=' + title : ''}`,
    }),
    product: builder.query<ProductByIdResponse, { sourceAccountId: string; productId: string }>({
      query: ({ sourceAccountId, productId }) =>
        `/api/shopify/${sourceAccountId}/products/${productId}`,
    }),
    collections: builder.query<CollectionsResponse[], { sourceAccountId: string }>({
      query: ({ sourceAccountId }) => `/api/shopify/${sourceAccountId}/collections`,
    }),
    tags: builder.query<string[], { sourceAccountId: string }>({
      query: ({ sourceAccountId }) => `/api/shopify/${sourceAccountId}/products/tags`,
    }),
  }),
})

export const { useProductQuery, useTagsQuery, useProductsQuery, useCollectionsQuery } = shopifyApi

export default shopifyApi
