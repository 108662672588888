import Box, { type BoxProps } from '@mui/joy/Box'
import { type FC } from 'react'

const Bar: FC<BoxProps> = ({ sx, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      py: 2.5,
      px: 2,
      gap: 1.5,
      alignItems: 'center',
      height: 80,
      ...sx,
    }}
    {...props}
  />
)

export default Bar
