import { useCallback } from 'react'

import { useLazyGetTextPreviewQuery } from '../../../../store/endpoints/postsTemplates'
import { isRichText } from './PostFormContainer.utils'

type TextEnricher = (text: string, productId?: string) => Promise<string>

export const useEnrichText = (): { enrichText: TextEnricher } => {
  const [getTextPreview] = useLazyGetTextPreviewQuery()

  const enrichText = useCallback(
    async (text: string, productId?: string): Promise<string> => {
      if (isRichText(text) && productId) {
        const { data } = await getTextPreview({ template: text, product: { id: productId } })

        return data?.description ? data.description : text
      }

      return text
    },
    [getTextPreview],
  )

  return { enrichText }
}
