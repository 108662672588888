import Sheet, { type SheetProps } from '@mui/joy/Sheet'
import Table, { type TableProps } from '@mui/joy/Table'
import { type FC } from 'react'

const StyledTable: FC<TableProps> & { Container: FC<SheetProps> } = ({ sx, ...props }) => {
  return (
    <Table
      hoverRow
      sx={{
        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
        '--TableCell-borderColor': 'var(--joy-palette-background-level1)',
        '--TableCell-paddingY': '20px',
        '--TableCell-paddingX': '10px',
        borderRadius: '0 0 md md',
        width: 'unset',
        '> tbody': {
          borderRadius: 'md',
        },
        ...sx,
      }}
      {...props}
    />
  )
}

const Container: FC<SheetProps> = ({ sx, ...props }) => {
  return (
    <Sheet
      variant="outlined"
      sx={{
        display: 'flex',
        width: '100%',
        borderRadius: 'sm',
        flexShrink: 1,
        overflowY: 'scroll',
        minHeight: 0,
        border: 'none',
        justifyContent: 'space-between',
        ...sx,
      }}
      {...props}
    />
  )
}

StyledTable.Container = Container

export default StyledTable
