import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import { StaticDatePicker, TimeField } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { type FC } from 'react'

const StyledStaticDatePicker = styled(StaticDatePicker)(
  () => `
  height: 290px;
`,
)

const MuiDatePicker: FC<{ value: Date; onChange: (newValue: Date) => void }> = ({
  value,
  onChange,
}) => {
  const handleDateChange = (event: any): void => {
    const newDateAndTime = new Date(value)
    const newDate = new Date(+event)
    newDateAndTime.setDate(newDate.getDate())
    newDateAndTime.setFullYear(newDate.getFullYear())
    newDateAndTime.setMonth(newDate.getMonth())
    onChange(newDateAndTime)
  }

  const handleTimeChange = (event: any): void => {
    const newDateAndTime = new Date(+value)
    const newTime = new Date(+event)
    newDateAndTime.setTime(newTime.getTime())
    onChange(newDateAndTime)
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <StyledStaticDatePicker
        value={dayjs(value)}
        onChange={handleDateChange}
        showDaysOutsideCurrentMonth
        displayStaticWrapperAs="desktop"
      />
      <TimeField
        value={dayjs(value)}
        onChange={handleTimeChange}
        sx={{ marginTop: 3 }}
        fullWidth
        size="small"
      />
    </Box>
  )
}

export default MuiDatePicker
