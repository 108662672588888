import type { Draft } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

import type { Session } from '../../types'
import api from '../endpoints/auth'
import type { RootState } from '../store'
import { expireSession } from './actions'

interface AuthState {
  session?: Session
}

const initialState = (): AuthState => {
  const expireDate = Cookies.get('Token-Expires')

  if (expireDate === undefined || expireDate === '') {
    return {}
  }

  return { session: { expireDate } }
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(expireSession, (draft: Draft<AuthState>) => {
        if (draft.session !== undefined) {
          draft.session.isExpired = true
        }
      })
      .addMatcher(api.endpoints.loginDev.matchFulfilled, (draft: Draft<AuthState>) => {
        const expireDate = Cookies.get('Token-Expires')

        if (expireDate !== undefined && expireDate !== '') {
          draft.session = { expireDate }
        } else {
          delete draft.session
        }
      })
      .addMatcher(api.endpoints.logout.matchFulfilled, (draft: Draft<AuthState>) => {
        if (draft.session !== undefined) {
          delete draft.session
        }
      })
  },
})

// export const {} = authSlice.actions

export default authSlice.reducer

export const selectSession = (rootState: RootState): Session | undefined => rootState.auth?.session
