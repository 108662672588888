import Card, { type CardProps } from '@mui/joy/Card'
import { type FC } from 'react'

const FormCard: FC<CardProps> = ({ sx, ...props }) => {
  return (
    <Card
      sx={{
        height: 'fit-content',
        p: 3,
        // flex: '1 1 518px',
        maxWidth: 693,
        minWidth: 518,
        ...sx,
      }}
      variant="plain"
      {...props}
    />
  )
}

export default FormCard
