import { useContext } from 'react'

import { ConfirmContext } from './components/ConfirmContextProvider/ConfirmContextProvider'
import { type ConfirmFn } from './useConfirm.types'

const useConfirm = (): ConfirmFn => {
  const confirm = useContext(ConfirmContext)

  if (confirm === undefined) {
    throw Error('ConfirmContext should be provided')
  }

  return confirm
}

export default useConfirm
