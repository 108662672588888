import Badge, { type BadgeProps } from '@mui/joy/Badge'
import { type FC } from 'react'

const RightBottomBadge: FC<BadgeProps> = (props) => (
  <Badge
    color="neutral"
    variant="plain"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeInset="14%"
    sx={{
      '--Badge-borderRadius': 'var(--joy-radius-sm)',
      '--Badge-paddingX': '0px',
      '--Badge-ringSize': '0.5px',
      '--Badge-minHeight': 'var(--joy-radius-sm)',
    }}
    {...props}
  />
)

export default RightBottomBadge
