import type { FC, SyntheticEvent } from 'react'
import Typography from '@mui/material/Typography'
import { isVideo } from '../../PostPreview.utils'
import { MediaEmpty, MediaImage, MediaVideo } from './Media.style'
import { useState } from 'react'

export interface MediaProps {
  src: string | undefined
  isVideoMuted?: boolean | undefined
  onTimeUpdate?: (time: number) => void
  className?: string
}

const Media: FC<MediaProps> = ({ src, isVideoMuted, onTimeUpdate, className }) => {
  const [isImageBroken, setIsImageBroken] = useState(false)

  const onTimeUpdateHandler = (e: SyntheticEvent<HTMLVideoElement>): void => {
    if (onTimeUpdate !== undefined) {
      const percentage = Math.floor((e.currentTarget.currentTime / e.currentTarget.duration) * 100)
      onTimeUpdate(percentage)
    }
  }

  if (isVideo(src)) {
    return <MediaVideo src={src} className={className} autoPlay muted={isVideoMuted} loop onTimeUpdate={onTimeUpdateHandler} />
  }

  if (src !== undefined && !isImageBroken) {
    return <MediaImage src={src} className={className} alt='' onError={() => { setIsImageBroken(true) }} />
  }

  return (
      <MediaEmpty className={className}>
        <Typography variant='body2' sx={{ color: (theme) => theme.palette.grey100 }}>Add Media</Typography>
      </MediaEmpty>
  )
}

export default Media
