import Box, { type BoxProps } from '@mui/joy/Box'
import { type FC, type ReactElement } from 'react'

export interface CardPreviewBoxProps extends BoxProps {
  preview?: ReactElement | null
}

const PreviewBox: FC<CardPreviewBoxProps> = ({ children, preview = null, sx, ...props }) => {
  return (
    <Box
      display="flex"
      gap={2}
      sx={{ overflow: 'auto', mb: 16, flexWrap: 'wrap', justifyContent: 'flex-start', ...sx }}
      {...props}
    >
      {children}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          minWidth: 470,
          maxWidth: 631,
          flex: 2,
          '@media (min-width: 1104px)': {
            alignItems: 'center',
          },
        }}
      >
        {preview}
      </Box>
    </Box>
  )
}

export default PreviewBox
