import dayjs from 'dayjs'

export function calculatePaidTime(expiresAt: string): string {
  const ms = dayjs(expiresAt).diff(new Date())

  if (ms < 0) {
    return 'Almost expired'
  }

  let paidTime = Math.floor(ms / (1000 * 60 * 60 * 24)) // full days

  if (paidTime !== 0) {
    return `${paidTime} more days`
  }

  paidTime = Math.floor(ms / (1000 * 60 * 60)) // full hours

  if (paidTime !== 0) {
    return `${paidTime} more hours`
  }

  paidTime = Math.floor(ms / (1000 * 60)) // full minutes

  return `${paidTime} more minutes`
}
