import { createSlice } from '@reduxjs/toolkit'

import { pushErrorNotification, pushSuccessNotification } from './actions'
import { pushNotificationReducer, removeNotificationReducer } from './reducers'
import { type NotificationsSlice } from './types'

const initialState: NotificationsSlice = {
  notifications: [],
}

export const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    pushNotification: pushNotificationReducer,
    removeNotification: removeNotificationReducer,
  },
  extraReducers: (builder) =>
    builder
      .addCase(pushSuccessNotification, pushNotificationReducer)
      .addCase(pushErrorNotification, pushNotificationReducer),
})

export default notificationsSlice.reducer

export const { pushNotification, removeNotification } = notificationsSlice.actions
