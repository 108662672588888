/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type {
  CompletionResponse,
  ExpandRequest,
  GenerateRequest,
  OptimizeRequest,
  ShortenRequest,
} from '../../types'
import api from '../api'

const aiApi = api.injectEndpoints({
  endpoints: (builder) => ({
    AICreate: builder.mutation<CompletionResponse, GenerateRequest>({
      query: (body: GenerateRequest) => ({
        url: 'api/ai/generate',
        method: 'POST',
        body,
      }),
    }),
    AIOptimize: builder.mutation<CompletionResponse, OptimizeRequest>({
      query: (body) => ({
        url: 'api/ai/optimize',
        method: 'POST',
        body,
      }),
    }),
    AIShorten: builder.mutation<CompletionResponse, ShortenRequest>({
      query: (body) => ({
        url: 'api/ai/shorten',
        method: 'POST',
        body,
      }),
    }),
    AIExpand: builder.mutation<CompletionResponse, ExpandRequest>({
      query: (body) => ({
        url: 'api/ai/expand',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useAICreateMutation,
  useAIExpandMutation,
  useAIShortenMutation,
  useAIOptimizeMutation,
} = aiApi

export default aiApi
