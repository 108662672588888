import type { FC, ReactElement } from 'react'
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom'

import CampaignPage from '../pages/Campaign/CampaignPage'
import CampaignsPage from '../pages/Campaigns/CampaignsPage'
import ExpiredPage from '../pages/Expired/ExpiredPage'
import LoginPage from '../pages/Login/LoginPage'
import NotFoundPage from '../pages/NotFound/NotFoundPage'
import PostPage from '../pages/Post/PostPage'
import PostsPage from '../pages/Posts/PostsPage'
import PrivacyPage from '../pages/Privacy/PrivacyPage'
import RegisterPage from '../pages/Register/RegisterPage'
// connect pages
import SettingsPage from '../pages/Settings/SettingsPage'
import { useAppSelector } from '../store'
import { selectSession } from '../store/auth/authSlice'
import Header from './Header'

const RequiredSession: FC = () => {
  const session = useAppSelector(selectSession)

  if (session === undefined) {
    return <Navigate to="/login" />
  }

  if (session.isExpired !== undefined && Boolean(session.isExpired)) {
    return <Navigate to="/expired" />
  }

  return <Outlet />
}

const MenuLayout: FC = () => {
  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

export const browserRouter = createBrowserRouter([
  {
    Component: RequiredSession,
    children: [
      {
        Component: MenuLayout,
        children: [
          { path: '/', element: <Navigate to="/posts?status=SCHEDULED" /> },
          { path: 'posts/:id', Component: PostPage },
          { path: 'posts/*', Component: PostsPage },
          { path: 'campaigns/*', Component: CampaignsPage },
          { path: 'campaigns/:id', Component: CampaignPage },
          { path: 'settings/', Component: SettingsPage },
          { path: 'privacy', Component: PrivacyPage },
        ],
      },
    ],
  },
  { path: '/', element: <Navigate to="/login" /> },
  { path: 'login', Component: LoginPage },
  { path: 'register', Component: RegisterPage },
  { path: 'privacy', Component: PrivacyPage },
  { path: 'sign-in', element: <Navigate to="/login" /> },
  { path: 'sign-up', element: <Navigate to="/register" /> },
  { path: 'expired', Component: ExpiredPage },
  { path: '/*', Component: NotFoundPage },
])

const Router = (): ReactElement => <RouterProvider router={browserRouter} />

export default Router
