import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { type PostResponse } from '../../../../types'
import { type PostTableProps } from '../../types'
import BasePostsTable from './BasePostsTable'

const PostsTableWithNavigation: FC<PostTableProps> = (props) => {
  const navigate = useNavigate()

  const handleRowClick = (post: PostResponse): void => {
    if (post?.id !== undefined) {
      navigate(post?.id)
    }
  }

  return <BasePostsTable {...props} onRowClick={handleRowClick} />
}

export default PostsTableWithNavigation
