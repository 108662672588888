import { createSlice } from '@reduxjs/toolkit'

import { selectReducer, unselectReducer } from './reducers'

const initialState = {
  selectedIds: [],
}

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    select: selectReducer,
    unselect: unselectReducer,
  },
})

export default campaignsSlice.reducer

export const { select, unselect } = campaignsSlice.actions
