import { useCallback, useMemo } from 'react'

import {
  useEditNotificationsMutation,
  useGetNotificationsQuery,
} from '../../../../store/endpoints/root'

interface Notifications {
  marketing: boolean
  transactions: boolean
}

interface UseNotificationsReturn {
  notifications: Notifications
  isLoading: boolean
  setNotifications: (settings: Notifications) => Promise<void>
}

export function useNotifications(): UseNotificationsReturn {
  const { data = [], isLoading } = useGetNotificationsQuery()
  const [editNotifications] = useEditNotificationsMutation()

  const notifications = useMemo(
    () =>
      data.reduce((settings, { type, enabled }) => {
        const key = type.toLowerCase() as keyof Notifications

        return { ...settings, [key]: Boolean(enabled) }
      }, {}) as unknown as Notifications,
    [data],
  )

  const setNotifications = useCallback(async (settings: Notifications): Promise<void> => {
    await editNotifications([
      { type: 'MARKETING', enabled: settings.marketing },
      { type: 'TRANSACTIONS', enabled: settings.transactions },
    ])
  }, [])

  return { notifications, isLoading, setNotifications }
}
