import { type FC } from 'react'

import SortLabel, { type Sort } from '../../../../ui/SortLabel/SortLabel'
import { ROWS_WIDTH } from '../../constants'
import { type PostHeaderProps } from '../../types'

const BaseHeader: FC<PostHeaderProps> = ({ sorts, onSortsChange }) => {
  const sortsMap = sorts?.reduce((map, sort) => {
    const [field, order] = sort.split(',')
    map.set(field, order)
    return map
  }, new Map())

  const createSortChangeHandler = (fieldSortName: string) => (newSort: Sort | null) => {
    const newSorts: string[] = []
    sortsMap?.delete(fieldSortName)

    if (newSort !== null) {
      sortsMap?.set(fieldSortName, newSort)
    }

    sortsMap?.forEach((order: string, field: string) => {
      newSorts.push(`${field},${order}`)
    })

    onSortsChange?.(newSorts)
  }

  return (
    <>
      <th style={{ width: ROWS_WIDTH.CHECKBOX, padding: '12px 6px' }}></th>
      <th style={{ width: ROWS_WIDTH.TITLE, padding: '12px 6px' }}>
        <SortLabel
          label="Title"
          sort={sortsMap?.get('product_title')}
          onSortChange={createSortChangeHandler('product_title')}
        />
      </th>
      <th style={{ width: ROWS_WIDTH.CREATED_ON, padding: '12px 6px' }}>
        <SortLabel
          label="Created"
          sort={sortsMap?.get('created_at')}
          onSortChange={createSortChangeHandler('created_at')}
        />
      </th>
      <th style={{ width: ROWS_WIDTH.PUBLISHED, padding: '12px 6px' }}>
        <SortLabel
          label="Scheduled"
          sort={sortsMap?.get('scheduled_time')}
          onSortChange={createSortChangeHandler('scheduled_time')}
        />
      </th>
      <th style={{ width: ROWS_WIDTH.PAGE, padding: '12px 6px' }}>
        <SortLabel
          label="Page"
          sort={sortsMap?.get('platform_account')}
          onSortChange={createSortChangeHandler('platform_account')}
        />
      </th>
    </>
  )
}

export default BaseHeader
