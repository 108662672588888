import Box from '@mui/joy/Box'
import JoyRadio, { radioClasses, type RadioProps } from '@mui/joy/Radio'
import { type FC } from 'react'

const Radio: FC<RadioProps> = (props) => {
  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        padding: '0 12px',
        color: 'neutral.700',
        '&:not([data-first-child])': {
          borderLeft: '1px solid',
          borderColor: 'divider',
        },
        [`&[data-first-child] .${radioClasses.action}`]: {
          borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
          borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
        },
        [`&[data-last-child] .${radioClasses.action}`]: {
          borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
          borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
        },
      })}
    >
      <JoyRadio
        disableIcon
        overlay
        variant="plain"
        sx={{
          '--RadioGroup-gap': 0,
        }}
        slotProps={{
          root: {
            color: 'neutral.700',
          },
          // input: { 'aria-label': postType },
          action: ({ checked }) => ({
            sx: {
              ...(checked === true && {
                bgcolor: 'neutral.plainActiveBg',
                '&:hover': {
                  bgcolor: 'neutral.plainActiveBg',
                },
              }),
              borderRadius: 0,
              transition: 'none',
            },
          }),
          label: { sx: { display: 'flex', alignItems: 'center', gap: 1 } },
        }}
        {...props}
      />
    </Box>
  )
}

export default Radio
