import Box from '@mui/joy/Box'
import Option from '@mui/joy/Option'
import Select, { type SelectProps } from '@mui/joy/Select'
import { type FC } from 'react'

import useSourceAccounts from '../../../hooks/useSourceAccounts'
import { Spinner } from '../../../ui'

const SourceAccountSelectV2: FC<SelectProps<string, false>> = ({ children, ...props }) => {
  const { sourceAccounts, isLoading, isFetching } = useSourceAccounts()

  return (
    <Select {...props}>
      {children}

      {(isLoading || isFetching) && (
        <Option value="loading" disabled>
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Spinner size="sm" />
          </Box>
        </Option>
      )}

      {sourceAccounts?.map(({ id, name }) => (
        <Option key={id} value={id}>
          {name}
        </Option>
      ))}
    </Select>
  )
}

export default SourceAccountSelectV2
