import * as yup from 'yup'

const campaignsFormSchema = yup.object({
  campaigns: yup
    .array()
    .of(
      yup.object({
        socialName: yup.string().oneOf(['FACEBOOK', 'INSTAGRAM']).required(),
        socialAccountId: yup.string().required('Required'),

        name: yup.string().required('Required'),
        type: yup.string().oneOf(['RANDOM', 'NEW_ITEM']).required('Required'),
        sourceAccountId: yup.string().required('Required'),
        productRuleType: yup.string().oneOf(['COLLECTIONS', 'TAGS']).required('Required'),
        tags: yup.array().when('productRuleType', (productRuleType: any, schema) => {
          return productRuleType === 'TAGS'
            ? schema
                .of(yup.string().required('Required'))
                .min(1, 'Field must have at least 1 item')
                .required('Required')
            : schema.notRequired()
        }),

        collectionIds: yup.array().when('productRuleType', (productRuleType: any, schema) => {
          return productRuleType === 'COLLECTIONS'
            ? schema
                .of(yup.string().required('Required'))
                .min(1, 'Field must have at least 1 item')
                .required('Required')
            : schema.notRequired()
        }),

        // collectionIds: yup
        //   .array()
        //   .of(yup.string().required('Required'))
        //   .min(1, 'Field must have at least 1 items')
        //   .required('Required'),

        postType: yup.string().oneOf(['POST', 'STORY']).required('Required'),
        mediaRule: yup.string().oneOf(['FIRST', 'ALL']).required('Required'),
        postTemplates: yup
          .array()
          .of(yup.string().required('Required'))
          .min(1, 'Field must have at least 1 items')
          .required('Required'),

        time: yup.string().required('Required'),
        days: yup
          .array()
          .of(
            yup
              .string()
              .oneOf(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'])
              .required('Required'),
          )
          .min(1, 'Field must have at least 1 items')
          .required('Required'),
      }),
    )
    .min(1)
    .required(),
})

export { campaignsFormSchema }
