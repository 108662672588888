import Button from '@mui/joy/Button'
import { type FC, useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useCampaignsQuery } from '../../store/endpoints/campaign'
import Bar from '../../ui/Bar/Bar'
import Pagination from '../../ui/Pagination/Pagination'
import TablePage from '../../ui/TablePage/TablePage'
import {
  getCampaignFilter,
  getCampaignStatus,
  getNormalizedParams,
  getPage,
} from './CampaignPage.utils'
import CampaignsFilter from './components/CampaignsFilter/CampaignsFilter'
import { type CampaignFilter } from './components/CampaignsFilter/types'
import CampaignsStatusesContainer from './components/CampaignsStatusesContainer/CampaignsStatusesContainer'
import { useCampaignTable } from './hooks/useCampaignTable'

const CampaignsPage: FC = () => {
  const [params, setParams] = useSearchParams()

  const { data: campaignPage, isLoading: isCampaignsLoading } = useCampaignsQuery(
    getNormalizedParams(params).toString(),
  )

  const status = getCampaignStatus(params)
  const page = getPage(params)

  const Table = useCampaignTable(status)

  const navigate = useNavigate()

  const handleFilterChange = useCallback(
    ({ search = '', socialAccountId = '' }: CampaignFilter): void => {
      setParams((params) => {
        search === '' ? params.delete('search') : params.set('search', search)
        socialAccountId === '' ? params.delete('social') : params.set('social', socialAccountId)

        return params
      })
    },
    [],
  )

  const filter = useMemo(() => getCampaignFilter(params), [params])

  const handlePageChange = useCallback((newPage: number): void => {
    setParams((prevSearchParams) => {
      newPage === 1
        ? prevSearchParams.delete('page')
        : prevSearchParams.set('page', newPage.toString())

      return prevSearchParams
    })
  }, [])

  return (
    <TablePage
      navigation={<CampaignsStatusesContainer activeStatusId={status} />}
      bar={
        <Bar>
          <CampaignsFilter flex={1} filter={filter} onFilterChange={handleFilterChange} />

          <Button
            size="md"
            onClick={() => {
              navigate('new')
            }}
          >
            New campaign
          </Button>
        </Bar>
      }
    >
      <Table campaigns={campaignPage?.content} isLoading={isCampaignsLoading} />

      {campaignPage?.totalPages !== undefined && campaignPage?.totalPages > 1 && (
        <Pagination page={page} onPageChange={handlePageChange} />
      )}
    </TablePage>
  )
}

export default CampaignsPage
