import Card, { type CardProps } from '@mui/joy/Card'
import CardActions from '@mui/joy/CardActions'
import CardContent from '@mui/joy/CardContent'
import CardOverflow from '@mui/joy/CardOverflow'
import Typography from '@mui/joy/Typography'
import { type FC, type ReactNode } from 'react'

import { LogoIcon } from '../../../../../../components/views'
import PlanPriceTypography, { type PlanPriceView } from '../PlanPriceTypography/PlanPriceTypography'

export interface PlanView extends PlanPriceView {
  isActive?: boolean
  billingText?: string
}

interface PlanCardProps extends CardProps {
  plan: PlanView
  actions?: ReactNode
}

const PlanCard: FC<PlanCardProps> = ({ plan, actions, sx, ...props }) => {
  return (
    <Card orientation="horizontal" variant="plain" sx={{ '--Card-padding': 0, ...sx }} {...props}>
      <CardOverflow sx={{ alignItems: 'center' }}>
        <LogoIcon width={44} height={44} />
      </CardOverflow>

      <CardContent sx={{ justifyContent: 'center', gap: 1 }}>
        <PlanPriceTypography sx={{ lineHeight: '1.25rem' }} plan={plan} />
        <Typography sx={{ lineHeight: '1.25rem' }} level="body-sm">
          {plan.billingText}
        </Typography>
      </CardContent>

      <CardActions sx={{ '--Button-radius': 'unset', gap: 1 }}>{actions}</CardActions>
    </Card>
  )
}

export default PlanCard
