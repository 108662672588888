import Box from '@mui/joy/Box'
import { type FC, type PropsWithChildren, type ReactNode } from 'react'

export interface TablePageProps extends PropsWithChildren {
  navigation: ReactNode
  bar: ReactNode
  paginator?: ReactNode
}

const TablePage: FC<TablePageProps> = ({ navigation, bar, children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box flexShrink={0}>{navigation}</Box>
      <Box
        width="100%"
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        minWidth={788}
        height="100%"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            maxWidth: 1124,
          }}
        >
          <Box sx={{ flex: '0 1' }}>{bar}</Box>
          <Box
            sx={{
              px: 2,
              pb: { xs: 2, sm: 2, md: 3 },
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              height: '100dvh',
              gap: 1,
              overflow: 'auto',
              justifyContent: 'space-between',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TablePage
