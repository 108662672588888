import { type FC } from 'react'

import NewslettersForm from './components/NewslettersForm/NewslettersForm'
import { useNotifications } from './useNotifications'

const NewslettersContainer: FC = () => {
  const { notifications, isLoading, setNotifications } = useNotifications()

  if (isLoading) {
    return null
  }

  return (
    <NewslettersForm
      defaultValues={{
        marketing: notifications.marketing,
        notification: notifications.transactions,
      }}
      onSubmit={async (values): Promise<void> => {
        await setNotifications({
          marketing: values.marketing,
          transactions: values.notification,
        })
      }}
    ></NewslettersForm>
  )
}

export default NewslettersContainer
