import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import RadioGroup from '@mui/joy/RadioGroup'
import Stack from '@mui/joy/Stack'
import Textarea from '@mui/joy/Textarea'
import Typography from '@mui/joy/Typography'
import { FormGroup } from '@mui/material'
import { type ChangeEvent, type FC } from 'react'
import { Controller, useController, useWatch } from 'react-hook-form'
import { TbFreezeRow, TbHistoryToggle, TbSquarePlus } from 'react-icons/tb'

import { AIDescriptionTools, TemplateTools } from '../../../../../../../../components/ai'
import {
  ProductAutocomplete,
  SourceAccountSelectV2,
} from '../../../../../../../../components/forms'
import ErrorMessage from '../../../../../../../../components/forms/ErrorMessage/ErrorMessage'
import { MediaWithProduct } from '../../../../../../../../ui/Media/MediaWithProduct'
import Radio from '../../../../../../../../ui/Radio/Radio'
import { type PostFormProps } from './PostForm.types'

const labelsSx = {
  fontWeight: 500,
  color: 'text.primary',
  mb: '6px',
}

const PostForm: FC<PostFormProps> = ({ control, index }) => {
  const sourceAccountId = useWatch({ control, name: `posts.${index}.sourceAccountId` })
  const productId = useWatch({ control, name: `posts.${index}.productId` })
  const caption = useWatch({ control, name: `posts.${index}.caption` })
  const postType = useWatch({ control, name: `posts.${index}.postType` })
  const socialName = useWatch({ control, name: `posts.${index}.socialName` })

  const {
    fieldState: { error: formError },
  } = useController({ control, name: `posts.${index}` })

  const {
    fieldState: { error: productError },
  } = useController({ control, name: `posts.${index}.productId` })
  const {
    fieldState: { error: captionError },
  } = useController({ control, name: `posts.${index}.caption` })
  const {
    fieldState: { error: mediaError },
  } = useController({ control, name: `posts.${index}.media` })

  const videoEditorDisabled =
    (postType === 'POST' && socialName === 'INSTAGRAM') ||
    (postType === 'POST' && socialName === 'FACEBOOK')

  return (
    <Stack gap={4}>
      <FormControl>
        <Typography sx={labelsSx} id="post-title-label" component="label">
          Title
        </Typography>

        <Controller
          key={`postType.${index}`}
          name={`posts.${index}.postType`}
          control={control}
          render={({ field }) => (
            <RadioGroup
              value={field.value ?? ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                field.onChange(event.target.value)
              }}
              variant="outlined"
              orientation="horizontal"
            >
              <Radio
                overlay
                disableIcon
                value="POST"
                label={
                  <>
                    <TbSquarePlus />
                    <Typography>Post</Typography>
                  </>
                }
              />
              {socialName === 'INSTAGRAM' && (
                <Radio
                  overlay
                  value="STORY"
                  disableIcon
                  label={
                    <>
                      <TbHistoryToggle />
                      <Typography>Story</Typography>
                    </>
                  }
                />
              )}
              <Radio
                overlay
                value="REEL"
                disableIcon
                label={
                  <>
                    <TbFreezeRow />
                    <Typography>Reel</Typography>
                  </>
                }
              />
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormGroup>
        <Typography sx={labelsSx} id="chose-source-account-select-label" component="label">
          Product
        </Typography>

        <Stack direction="row" gap={2} flex={1}>
          <Controller
            name={`posts.${index}.sourceAccountId`}
            control={control}
            render={({ field }) => {
              return (
                <SourceAccountSelectV2
                  sx={{ flex: 1 }}
                  placeholder="Choose shop"
                  onChange={(_, newValue) => {
                    field.onChange(newValue)
                  }}
                  value={field.value}
                />
              )
            }}
          />

          {sourceAccountId !== undefined && sourceAccountId !== null && (
            <Controller
              name={`posts.${index}.productId`}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <ProductAutocomplete
                      color={productError !== undefined ? 'danger' : 'neutral'}
                      sourceAccountId={sourceAccountId}
                      options={[]}
                      sx={{ flex: 2 }}
                      placeholder="Choose product"
                      value={field.value}
                      onChange={(_, newValue) => {
                        field.onChange(newValue)
                      }}
                      error={productError !== undefined}
                    />
                  </>
                )
              }}
            />
          )}
        </Stack>
        <ErrorMessage error={productError} />
      </FormGroup>

      <FormControl>
        <Typography id="post-media-label" sx={labelsSx} component="label">
          Include media
        </Typography>

        <MediaWithProduct
          videoEditorDisabled={videoEditorDisabled}
          control={control}
          productId={productId}
          sourceAccountId={sourceAccountId}
          name={`posts.${index}.media`}
          error={mediaError !== undefined}
        />

        <ErrorMessage error={mediaError} />
        {formError?.type === 'check-video' && <ErrorMessage error={formError} />}
      </FormControl>

      {postType === 'POST' && (
        <FormGroup>
          <Typography id="post-caption-label" sx={labelsSx} component="label">
            Caption
          </Typography>
          <Controller
            name={`posts.${index}.caption`}
            control={control}
            render={({ field }) => {
              return (
                <Stack gap={1}>
                  <TemplateTools
                    text={field.value ?? ''}
                    onTextChange={(newValue) => {
                      field.onChange(newValue)
                    }}
                  />
                  <Box sx={{ position: 'relative' }}>
                    <Box sx={{ position: 'absolute', zIndex: 1, p: '12px' }}>
                      <AIDescriptionTools
                        productId={productId ?? ''}
                        sourceAccountId={sourceAccountId ?? ''}
                        accountPlatform={'FACEBOOK'}
                        description={field.value ?? ''}
                        onChange={(newDescription: string): void => {
                          field.onChange(newDescription)
                        }}
                      />
                    </Box>
                    <Textarea
                      sx={{ pt: 6 }}
                      minRows={3}
                      placeholder="Enter or generate caption..."
                      error={captionError !== undefined}
                      {...field}
                    />
                  </Box>
                </Stack>
              )
            }}
          />

          <Typography level="body-sm" sx={{ mt: '6px' }}>
            {caption === undefined || caption === null
              ? '2,200 characters'
              : `${caption?.trim()?.length} of 2200 characters`}
          </Typography>

          <ErrorMessage error={captionError} />
        </FormGroup>
      )}
    </Stack>
  )
}

export default PostForm
