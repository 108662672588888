/* eslint-disable @typescript-eslint/no-unused-vars */
import { IconButton } from '@mui/joy'
import Button from '@mui/joy/Button'
import Menu from '@mui/joy/Menu'
import MenuItem from '@mui/joy/MenuItem'
import Tab from '@mui/joy/Tab'
import TabList from '@mui/joy/TabList'
import Tabs, { type TabsProps } from '@mui/joy/Tabs'
import { type FC, type SyntheticEvent, useEffect, useRef, useState } from 'react'
import { TbChevronDown, TbX } from 'react-icons/tb'

import { type NotEmptySocialAccount } from '../../../hooks/useSocialAccounts'
import SocialAccountAvatar from '../../views/SocialAccountAvatar/SocialAccountAvatar'

interface SocialAccountTabsProps extends TabsProps {
  onTabAdded?: (socialAccount: NotEmptySocialAccount) => void
  onTabRemoved?: (socialAccountId: string) => void
  socialAccounts: NotEmptySocialAccount[]
  readonly?: boolean
}

const SocialAccountsTabs: FC<SocialAccountTabsProps> = ({
  readonly = false,
  socialAccounts,
  onTabAdded,
  onTabRemoved,
  children,
  value,
  defaultValue,
  ...props
}) => {
  const [selectedIds, setSelectedIds] = useState<string[]>(
    typeof defaultValue === 'string' ? [defaultValue] : [],
  )

  const [isOpen, setIsOpen] = useState(false)

  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleMenuClose = (): void => {
    setIsOpen(false)
  }

  const createMenuItemClickHandler = (socialAccount: NotEmptySocialAccount) => (): void => {
    setSelectedIds((currentIds) => [...currentIds, socialAccount.id])
    setIsOpen(false)
    onTabAdded?.(socialAccount)
  }

  const createTabCloseClickHandler =
    (socialAccountId: string) =>
    (event: SyntheticEvent<unknown>): void => {
      event.stopPropagation()
      setSelectedIds((currentIds) => currentIds.filter((id) => id !== socialAccountId))
      onTabRemoved?.(socialAccountId)
    }

  const handleAddClick = (): void => {
    setIsOpen(true)
  }

  useEffect(() => {
    if (typeof defaultValue === 'string') {
      setSelectedIds([defaultValue])
    }
  }, [defaultValue])

  return (
    <Tabs variant="outlined" value={value} defaultValue={defaultValue} {...props}>
      <TabList
        disableUnderline
        sx={{
          height: 44,
          px: 2,
          gap: 2,
          borderRadius: 'sm',
        }}
      >
        {socialAccounts
          ?.filter(({ id }) => selectedIds.includes(id))
          ?.map((socialAccount) => (
            <Tab
              key={socialAccount.id}
              sx={{ px: 2.5, py: 1, borderRadius: 'sm' }}
              component="div"
              disableIndicator
              value={socialAccount.id}
            >
              <SocialAccountAvatar
                sx={{ '--Avatar-size': '28px' }}
                size="sm"
                socialAccount={socialAccount}
              />
              {socialAccount.name}
              {selectedIds.length !== 1 && (
                <IconButton size="xs" onClick={createTabCloseClickHandler(socialAccount.id)}>
                  <TbX size={28} />
                </IconButton>
              )}
            </Tab>
          ))}

        {!readonly && (
          <Button
            color="neutral"
            variant="outlined"
            ref={anchorRef}
            disabled={selectedIds.length === socialAccounts?.length}
            onClick={handleAddClick}
            endDecorator={<TbChevronDown />}
          >
            Add page
          </Button>
        )}

        {Array.isArray(socialAccounts) && (
          <Menu open={isOpen} onClose={handleMenuClose} anchorEl={anchorRef.current}>
            {socialAccounts
              .filter(({ id }) => !selectedIds.includes(id))
              .map((socialAccount) => (
                <MenuItem
                  key={socialAccount.id}
                  onClick={createMenuItemClickHandler(socialAccount)}
                >
                  <SocialAccountAvatar size="sm" socialAccount={socialAccount} />
                  {socialAccount.name}
                </MenuItem>
              ))}
          </Menu>
        )}
      </TabList>
      {children}
    </Tabs>
  )
}

export default SocialAccountsTabs
