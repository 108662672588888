import Checkbox from '@mui/joy/Checkbox'
import Sheet from '@mui/joy/Sheet'
import Table from '@mui/joy/Table'
import { type FC } from 'react'

import { type PostResponse } from '../../../../types'
import { type PostTableProps } from '../../types'

interface BasePostTableProps extends PostTableProps {
  onRowClick?: (post: PostResponse) => void
}

const BasePostsTable: FC<BasePostTableProps> = ({
  posts,
  header: Header,
  row: Row,
  isHideCheckboxes = false,
  sorts,
  onSortsChange,
  selected = [],
  onSelectedChange,
  onRowClick,
}) => {
  const createRowClickHandler = (post: PostResponse) => () => {
    onRowClick?.(post)
  }

  return (
    <Sheet
      className="OrderTableContainer"
      variant="outlined"
      sx={{
        display: 'flex',
        width: '100%',
        borderRadius: 'sm',
        flexShrink: 1,
        overflowY: 'scroll',
        minHeight: 0,
        border: 'none',
        justifyContent: 'space-between',
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        hoverRow
        sx={{
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-borderColor': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '20px',
          '--TableCell-paddingX': '10px',
          borderRadius: '0 0 md md',
          width: 'unset',
          '> tbody': {
            borderRadius: 'md',
          },
        }}
      >
        <thead>
          <tr>
            {!isHideCheckboxes && (
              <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                <Checkbox
                  variant="outlined"
                  size="md"
                  color="neutral"
                  indeterminate={selected.length > 0 && selected.length !== posts.length}
                  checked={selected.length === posts.length}
                  onChange={(event) => {
                    onSelectedChange?.(
                      event.target.checked ? posts.map((post) => post.id as string) : [],
                    )
                  }}
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </th>
            )}
            <Header sorts={sorts} onSortsChange={onSortsChange} />
          </tr>
        </thead>
        <tbody>
          {posts.map((post) => (
            <tr key={post.id}>
              {!isHideCheckboxes && (
                <td style={{ width: 48, padding: '12px 6px', textAlign: 'center' }}>
                  <Checkbox
                    variant="outlined"
                    size="md"
                    color="neutral"
                    checked={selected.includes(post?.id as string)}
                    onChange={(event) => {
                      onSelectedChange?.((ids) =>
                        event.target.checked
                          ? ids.concat(post?.id as string)
                          : ids.filter((itemId) => itemId !== (post?.id as string)),
                      )
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </td>
              )}
              <Row post={post} onClick={createRowClickHandler(post)} />
            </tr>
          ))}
        </tbody>
      </Table>
    </Sheet>
  )
}

export default BasePostsTable
