import styled from '@emotion/styled'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import type { ReactElement } from 'react'

const SUPPORT_MAIL = 'amplorium@gmail.com'

const StyledContainer = styled(Container)`
  > h6,
  span,
  h5 {
    margin-top: 8px;
  }
`

const PrivacyPage = (): ReactElement => {
  return (
    <StyledContainer maxWidth="lg">
      <Typography variant="h5">Privacy Policy</Typography>
      <Typography variant="caption">
        Facebook & Instagram Amplorium (the App), created by Amplorium Inc. (the Company), provides
        automation services (the Service) to Shopify developers and merchants. This Privacy Policy
        describes how personal information is collected, used, and shared when you sign up for an
        account or connect a Shopify store to the App.
      </Typography>
      <Typography variant="h6">Personal Information the App Collects</Typography>
      <Typography variant="caption">
        The Company receives your name and email address when you access the App. The Company itself
        does not seek to store customer information, but when providing the services you configure,
        it may receive and store customer information. You may request the deletion of this
        information at any time.
      </Typography>
      <Typography variant="h6">How Do We Use Your Personal Information?</Typography>
      <Typography variant="caption">
        We promise never to sell your personal information. We may use your information to help
        provide you with enhanced service.
      </Typography>
      <Typography variant="h6">Sharing Your Personal Information</Typography>
      <Typography variant="caption">
        Personal Information we collect about you may be shared with the staff at the Company to
        provide customer support services. Your contact information may be used to contact you for
        user research purposes so we may improve the service for you in the future. Finally, we may
        also share your Personal Information to comply with applicable laws and regulations, to
        respond to a subpoena, search warrant or other lawful request for information we receive, or
        to protect our rights otherwise.
      </Typography>
      <Typography variant="h6">Your Rights</Typography>
      <Typography variant="caption">
        If you are a European resident, you have the right to access personal information we hold
        about you and to ask that your personal information be corrected, updated, or deleted, per
        the GDPR. If you would like to exercise this right, please contact us through the email
        below. Additionally, if you are a European resident, please be advised we are processing
        your information to pursue our legitimate business interests listed above. Your information
        may be stored on 3rd party servers in the US, Canada or Europe.
      </Typography>
      <Typography variant="h6">Data Retention</Typography>
      <Typography variant="caption">
        The App retains records describing your Shopify store and your ownership thereof, as
        provided by Shopify, to record your store’s relationship with the App. These records are
        limited to your Shopify store name, myshopify.com subdomain, owner name and email address.
        We may update this privacy policy occasionally to reflect, for example, changes to our
        practices or for other operational, legal or regulatory reasons.
      </Typography>
      <Typography variant="h6">Contact Us</Typography>
      <Typography variant="caption">
        If you have any questions or require clarification about this privacy policy, please email
        us at{' '}
        <Link href={`mailto:${SUPPORT_MAIL}`} variant="body2">
          {SUPPORT_MAIL}
        </Link>
      </Typography>
    </StyledContainer>
  )
}

export default PrivacyPage
