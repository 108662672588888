import Avatar from '@mui/joy/Avatar'
import Badge from '@mui/joy/Badge'
import IconButton from '@mui/joy/IconButton'
import { styled } from '@mui/joy/styles'
import type { ReactElement } from 'react'
import { TbTrash } from 'react-icons/tb'

import { AnimationBox } from './AnimationBox'
import { isVideo } from './utils'

const AttachedMediaContainer = styled('div')`
  position: relative;
  > button {
    display: none;
    position: absolute;
    left: 7px;
    top: 7px;
    z-index: 1;
    width: 28px;
    height: 28px;
    padding: 4px;
  }
  &:hover > button {
    display: inline-flex;
    &:hover {
    }
  }
`

interface AttachedMediaProps {
  url?: string
  selected: boolean
  name: string
  index: number
  removable: boolean
  markAsError?: boolean
  onRemovePicture?: () => void
  onClick: () => void
}

export const AttachedMedia = ({
  url,
  name,
  selected,
  index,
  removable = true,
  markAsError = false,
  onRemovePicture,
  onClick,
}: AttachedMediaProps): ReactElement => {
  const picture = (
    <Avatar
      sx={{
        height: 68,
        width: 68,
        cursor: 'pointer',
        borderRadius: 'sm',
        border: (theme) =>
          selected
            ? `2px solid ${markAsError ? theme.palette.danger[500] : theme.palette.primary[500]}`
            : 'none',
      }}
      onClick={onClick}
    >
      {url && isVideo(url) ? (
        <video height={68} width={68} autoPlay loop>
          <source src={url} />
        </video>
      ) : (
        <img height={68} width={68} src={url} alt={name} style={{ objectFit: 'cover' }} />
      )}
    </Avatar>
  )

  return (
    <AnimationBox>
      <AttachedMediaContainer>
        {removable && (
          <IconButton
            size="xs"
            variant="soft"
            sx={{
              borderRadius: 'sm',
              color: (theme) => theme.palette.danger[500],
              background: (theme) => theme.palette.background.body,
            }}
            onClick={onRemovePicture}
          >
            <TbTrash />
          </IconButton>
        )}
        {selected ? (
          <Badge color={markAsError ? 'danger' : 'primary'} badgeContent={index + 1}>
            {picture}
          </Badge>
        ) : (
          picture
        )}
      </AttachedMediaContainer>
    </AnimationBox>
  )
}
