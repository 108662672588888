import { type FC } from 'react'

import { PAYMENT_STATUSES } from '../../../../../../types/constants'
import ActiveSubscriptionAlert from './ActiveSubscriptionAlert'
import ExpiredSubscriptionAlert from './ExpiredSubscriptionAlert'
import { type SubscriptionAlertProps } from './SubscriptionAlert.types'
import TrialSubscriptionAlert from './TrialSubscriptionAlert'

const SubscriptionAlert: FC<SubscriptionAlertProps> = (props) => {
  const { status } = props

  if (status.active === true) {
    switch (status.status) {
      case PAYMENT_STATUSES.TRIALING:
        return <TrialSubscriptionAlert {...props} />

      case PAYMENT_STATUSES.CANCELED:
      case PAYMENT_STATUSES.ACTIVE:
        return <ActiveSubscriptionAlert {...props} />

      default:
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unexpected status: ${status?.status}`)
    }
  }

  return <ExpiredSubscriptionAlert {...props} />
}

export default SubscriptionAlert
