import { type Draft, type PayloadAction } from '@reduxjs/toolkit'

import { type CampaignsSlice } from './types'

export const selectReducer = (
  state: Draft<CampaignsSlice>,
  action: PayloadAction<string>,
): void => {
  state.selectedIds.push(action.payload)
}

export const unselectReducer = (
  state: Draft<CampaignsSlice>,
  action: PayloadAction<string>,
): void => {
  state.selectedIds = state.selectedIds.filter((id) => id !== action.payload)
}
