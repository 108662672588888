/* eslint-disable @typescript-eslint/no-invalid-void-type */
import api from '../api'

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loginDev: builder.mutation<void, string>({
      query: (userId: string) => ({ url: `auth/login-dev?userId=${userId}`, method: 'POST' }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({ url: 'auth/logout', method: 'POST' }),
      invalidatesTags: ['User'],
    }),
  }),
})

export const { useLogoutMutation, useLoginDevMutation } = authApi

export default authApi
