import { type Post } from '@amplorium/post-preview/dist/types'

import { type MediaValue, type PostResponse } from '../../../../types'

export const toPostPreview = (post: PostResponse): Post => ({
  account: {
    name: post.sourceAccount?.platform ?? '',
    url: 'example.com',
  },
  description: post.platformData?.caption ?? 'som1',
  socialType: post.socialAccount?.platform ?? ('INSTAGRAM' as any),
  postType: post.postType ?? ('POST' as any),
  media: post.media?.filter((media): media is MediaValue => !!media?.id) ?? ([] as any),
})
