import Alert from '@mui/joy/Alert'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'
import { TbInfoSquareRounded } from 'react-icons/tb'

import { type SubscriptionAlertProps } from './SubscriptionAlert.types'
import { calculatePaidTime } from './SubscriptionAlert.util'

const TrialSubscriptionAlert: FC<SubscriptionAlertProps> = ({ status, ...props }) => {
  return (
    <Alert variant="soft" color="neutral" startDecorator={<TbInfoSquareRounded />} {...props}>
      {status?.expiresAt !== undefined && (
        <Typography>
          Your free trial ends in{' '}
          <Typography component="span" sx={{ fontWeight: 700 }}>
            {calculatePaidTime(status.expiresAt)}
          </Typography>
          . Use it fully – no charges until it’s over!
        </Typography>
      )}
      {status?.expiresAt === undefined && <Typography>Enjoy your free trial!</Typography>}
    </Alert>
  )
}

export default TrialSubscriptionAlert
