import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { type RootState, useAppDispatch } from '../store'
import { useLazyPostQuery } from '../store/endpoints/posts'
import { untrackId } from '../store/posts/actions'
import { sendPostNotification } from '../utils/sendPostNotification'

const useTrackedPosts = (): void => {
  const [lazyPostQuery] = useLazyPostQuery()
  const trackedIds: string[] = useSelector((state: RootState) => state.posts.trackedIds)
  const dispatch = useAppDispatch()
  const lock = useRef<boolean>(false)

  useEffect(() => {
    const checkTrackedIds = async (trackedIds: string[]): Promise<void> => {
      try {
        await Promise.all(
          trackedIds.map(async (id) => {
            dispatch(untrackId(id))
            const { data: post } = await lazyPostQuery(id)
            if (post) {
              sendPostNotification(dispatch, post)
            }
          }),
        )
      } catch (error: unknown) {
        trackedIds.forEach((id) => dispatch(untrackId(id)))
      }
      lock.current = false
    }

    if (trackedIds.length !== 0 && !lock.current) {
      lock.current = true
      void checkTrackedIds(trackedIds)
    }
  }, [trackedIds.length, lock])
}

export default useTrackedPosts
