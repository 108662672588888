import { type Draft, type PayloadAction } from '@reduxjs/toolkit'

import { type PostsSlice } from './types'

export const selectReducer = (state: Draft<PostsSlice>, action: PayloadAction<string>): void => {
  state.selectedIds.push(action.payload)
}

export const unselectReducer = (state: Draft<PostsSlice>, action: PayloadAction<string>): void => {
  state.selectedIds = state.selectedIds.filter((id) => id !== action.payload)
}
