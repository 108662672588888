import Box from '@mui/joy/Box'
import Checkbox from '@mui/joy/Checkbox'
import Option from '@mui/joy/Option'
import Select, { type SelectProps } from '@mui/joy/Select'
import type { ReactElement } from 'react'

import { useCollectionsQuery } from '../../../store/endpoints/shopify'
import { Spinner } from '../../../ui'

interface CollectionsMultiSelectProps extends SelectProps<string, true> {
  sourceAccountId?: string
}

const CollectionsMultiSelect = ({
  sourceAccountId,
  children,
  ...props
}: CollectionsMultiSelectProps): ReactElement => {
  const {
    data: collections,
    isLoading,
    isFetching,
  } = useCollectionsQuery(
    { sourceAccountId: sourceAccountId ?? '' },
    { skip: sourceAccountId === '' || sourceAccountId === undefined || sourceAccountId === null },
  )

  return (
    <Select multiple {...props}>
      {children}

      {(isLoading || isFetching) && (
        <Option value="loading" disabled>
          <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
            <Spinner size="sm" />
          </Box>
        </Option>
      )}

      {collections?.map(({ id, title }) => (
        <Option key={id} value={id}>
          <Checkbox
            checked={
              Array.isArray(props.value) && id !== undefined ? props.value.includes(id) : false
            }
            variant="outlined"
            size="md"
            color="neutral"
            sx={{ verticalAlign: 'text-bottom' }}
          />
          {title}
        </Option>
      ))}
    </Select>
  )
}

export default CollectionsMultiSelect
