import { type Dispatch } from '@reduxjs/toolkit'

import { pushErrorNotification, pushSuccessNotification } from '../store/notifications/actions'
import { type PostResponse } from '../types'

export function sendPostNotification(dispatch: Dispatch, postResponse: PostResponse): void {
  if (!postResponse.status) {
    return
  }

  const status = postResponse.status
  const platform = postResponse.socialAccount?.platform ?? ''
  const postType = postResponse.postType ?? ''

  if (status === 'SENT') {
    const message = `Post to ${platform} (${postType}) was successfully published`
    dispatch(pushSuccessNotification({ message }))
  }
  if (status === 'FAILED') {
    const message = `Post to ${platform} (${postType}) was not published`
    dispatch(pushErrorNotification({ message }))
  }
}
