import Button from '@mui/joy/Button'
import Input from '@mui/joy/Input'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import type { ReactElement } from 'react'
import { useState } from 'react'

export type CloseVerdict =
  | {
      inputValue: string
      confirmed: true
    }
  | { confirmed: false }

export interface PopupWithInputProps {
  title?: string
  confirm?: string
  cancel?: string
  description?: string
  inputPlaceholder?: string
  onClose: (verdict: CloseVerdict) => void
  initialValue?: string
  open: boolean
}

const PopupWithInput = ({
  open,
  initialValue,
  onClose,
  ...props
}: PopupWithInputProps): ReactElement => {
  const [value, setValue] = useState<string | undefined>(initialValue)

  const handleClose = (): void => {
    onClose({ confirmed: false })
  }

  const handleChange = (event: any): void => {
    setValue(event?.target.value)
  }

  const handleConfirm = (): void => {
    onClose({ inputValue: value ?? '', confirmed: true })
  }

  return (
    <Modal onClose={handleClose} open={open}>
      <ModalDialog>
        <ModalClose />
        <Typography>{props?.title ?? 'Please input field'}</Typography>
        {props?.description !== undefined && <Typography>{props?.description}</Typography>}
        <Input
          autoFocus
          placeholder={props?.inputPlaceholder ?? 'Type is here...'}
          fullWidth
          value={value}
          onChange={handleChange}
        />
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {props?.cancel ?? 'Cancel'}
          </Button>
          <Button disabled={value === ''} onClick={handleConfirm}>
            {props?.confirm ?? 'Confirm'}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  )
}

export default PopupWithInput
