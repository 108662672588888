import { type FC, Fragment, type PropsWithChildren, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from '../../store'
import { removeNotification } from '../../store/notifications/notificationsSlice'
import { selectNotifications } from '../../store/notifications/selectors'
import { type NotificationExtra, type NotificationType } from '../../store/notifications/types'
import ErrorSnackbar from './ErrorSnackbar'
import SuccessSnackbar from './SuccessSnackbar'
import { type NotificationViewByType } from './types'

const NOTIFICATIONS_DEFAULTS: NotificationExtra = {
  delay: 5000,
}

const ANIMATION_RENDER_TIME = 200

const VIEWS: NotificationViewByType = {
  SUCCESS: SuccessSnackbar,
  ERROR: ErrorSnackbar,
}

const NotificationsContainer: FC<PropsWithChildren> = ({ children }) => {
  const notifications = useSelector(selectNotifications)

  const activeNotification = notifications[0]

  const [isOpen, setIsOpen] = useState(false) // for smooth animation

  const appDispatch = useAppDispatch()

  useEffect(() => {
    setIsOpen(notifications[0] !== undefined)
  }, [notifications])

  const handleClose = (_: unknown, reason: string): void => {
    if (reason === 'clickaway') {
      return
    }

    if (activeNotification !== undefined) {
      setIsOpen(false)

      setTimeout(() => {
        appDispatch(removeNotification(activeNotification.id)) // for smooth animation
      }, ANIMATION_RENDER_TIME)
    }
  }

  const autoHideDuration = activeNotification?.extra?.delay ?? NOTIFICATIONS_DEFAULTS.delay

  const NotificationSnackbar =
    VIEWS[(activeNotification?.type as unknown as NotificationType) ?? 'SUCCESS']

  return (
    <Fragment>
      {activeNotification !== undefined && (
        <NotificationSnackbar
          key={activeNotification?.id}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={isOpen}
          onClose={handleClose}
          autoHideDuration={autoHideDuration}
          notification={activeNotification}
        />
      )}
      {children}
    </Fragment>
  )
}

export default NotificationsContainer
