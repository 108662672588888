import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import CardOverflow from '@mui/joy/CardOverflow'
import Stack, { type StackProps } from '@mui/joy/Stack'
import { type ReactNode } from 'react'

export interface HorizontalCardStackProps extends StackProps<any> {
  icon: ReactNode
  content: ReactNode
  action: ReactNode
}

export default function HorizontalCardStack({
  icon,
  content,
  action,
  ...props
}: HorizontalCardStackProps): ReactNode {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" {...props}>
      <Card orientation="horizontal" variant="plain" sx={{ '--Card-padding': 0 }}>
        <CardOverflow sx={{ alignItems: 'center' }}>{icon}</CardOverflow>
        <CardContent sx={{ justifyContent: 'center' }}>{content}</CardContent>
      </Card>
      {action}
    </Stack>
  )
}
