// import Select from '@mui/joy/Select'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
// import Box from '@mui/material/Box'
import type { ReactElement } from 'react'
import { useState } from 'react'

import {
  useCreatePostTemplateMutation,
  useDeletePostTemplateMutation,
  useEditPostTemplateMutation,
  useGetPostTemplatesQuery,
} from '../../../store/endpoints/postsTemplates'
import type { CloseVerdict } from '../../../ui/PopupWithInput/PopupWithInput'
import PopupWithInput from '../../../ui/PopupWithInput/PopupWithInput'
import PostTemplateSelect from './PostTemplateSelect'

interface TemplateToolsProps {
  text: string
  onTextChange: (newText: string) => void
}

const TemplateTools = ({ text, onTextChange }: TemplateToolsProps): ReactElement => {
  const { data, refetch: refetchTemplates } = useGetPostTemplatesQuery()
  const [createTemplate] = useCreatePostTemplateMutation()
  const [editTemplate] = useEditPostTemplateMutation()
  const [deleteTemplate] = useDeletePostTemplateMutation()

  const [mode, setMode] = useState<'create' | 'edit' | 'close'>('close')

  const templates = data ?? []
  const activeTemplate = templates.find(({ template }) => text === template)

  const handleSelectChange = (_: any, value: string | null): void => {
    if (value === null) {
      return
    }

    const newTemplate = templates.find(({ id }) => id === value)

    if (newTemplate?.template !== undefined) {
      onTextChange(newTemplate?.template)
    } else {
      onTextChange('')
    }

    // setActiveTemplateId(newTemplate?.id)
  }

  const handleClose = async (verdict: CloseVerdict): Promise<void> => {
    setMode('close')

    if (verdict.confirmed) {
      switch (mode) {
        case 'create': {
          await createTemplate({ name: verdict.inputValue, template: text })
          // const id = 'data' in createResult && createResult?.data?.id
          // if (typeof id === 'string') {
          //   setActiveTemplateId(id)
          // }

          break
        }
        case 'edit': {
          if (activeTemplate?.id === undefined) {
            throw Error('Editing template does not contain id')
          }
          await editTemplate({
            id: activeTemplate.id,
            name: verdict.inputValue,
            template: text,
          })
          break
        }
      }
      void refetchTemplates()
    }
  }

  const handleSaveAsNewClick = (): void => {
    setMode('create')
  }

  const handleEditClick = (): void => {
    setMode('edit')
  }

  const handleDeleteClick = async (): Promise<void> => {
    if (activeTemplate?.id === undefined) {
      throw Error('Deleting template does not contain id')
    }

    await deleteTemplate(activeTemplate?.id)

    void refetchTemplates()
    if (templates.length > 0) {
      // setActiveTemplateId(templates[0].id)

      if (templates[0].template !== undefined) {
        onTextChange(templates[0].template)
      }
    }
  }

  const isOpen = mode === 'edit' || mode === 'create'
  const isCanBeDeleted = (text === '' || text === undefined) && activeTemplate?.id !== undefined
  const isCanBeSaved = text !== '' && text !== undefined // template?.template !== text
  const isCanBeUpdated =
    activeTemplate?.id !== undefined &&
    activeTemplate?.template !== text &&
    activeTemplate?.type !== 'SYSTEM'

  return (
    <Box display="flex" gap={2}>
      {isOpen && (
        <PopupWithInput
          initialValue={activeTemplate?.name ?? ''}
          onClose={handleClose}
          open={isOpen}
        />
      )}

      <PostTemplateSelect
        placeholder="Choose template"
        sx={{ flex: 1, height: 40 }}
        value={activeTemplate?.id}
        onChange={handleSelectChange}
      />

      {isCanBeUpdated && (
        <Button variant="outlined" onClick={handleEditClick}>
          Update
        </Button>
      )}
      {isCanBeSaved && (
        <Button variant="outlined" onClick={handleSaveAsNewClick}>
          Save as new
        </Button>
      )}
      {isCanBeDeleted && (
        <Button variant="outlined" onClick={handleDeleteClick}>
          Delete
        </Button>
      )}
    </Box>
  )
}

export default TemplateTools
