import { type FC } from 'react'
import { type IconBaseProps } from 'react-icons'
import { TbFreezeRow, TbHistoryToggle, TbSquarePlus } from 'react-icons/tb'

interface PostTypeIconProps extends IconBaseProps {
  postType?: 'POST' | 'STORY' | 'REEL'
}

const PostTypeIcon: FC<PostTypeIconProps> = ({ postType, ...props }) => {
  switch (postType) {
    case 'REEL':
      return <TbFreezeRow {...props} />
    case 'POST':
      return <TbSquarePlus {...props} />
    case 'STORY':
      return <TbHistoryToggle {...props} />
    default:
      return null
  }
}

export default PostTypeIcon
