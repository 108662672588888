import Typography, { type TypographyProps } from '@mui/joy/Typography'
import { type FC } from 'react'

import { type PostResponse } from '../../../../types'
import { isNotNullable } from '../../../../utils/typesHelper'

interface PostTitleProps extends TypographyProps {
  post: PostResponse
}

const PostTitle: FC<PostTitleProps> = ({ post, sx, ...props }) => {
  const commonSx = {
    fontSize: 'sm',
    lineHeight: 'sm',
    fontWeight: 'md',
    display: 'inline-block',
    width: 440,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...sx,
  }

  return (
    <>
      <Typography component="span" sx={commonSx} {...props}>
        {post?.product?.title}
      </Typography>
      {isNotNullable(post?.platformData?.caption) && (
        <Typography
          component="span"
          sx={{
            mt: '2px',
            opacity: 0.8,
            ...commonSx,
          }}
          {...props}
        >
          {post?.platformData?.caption}
        </Typography>
      )}
    </>
  )
}

export default PostTitle
