/* eslint-disable @typescript-eslint/no-invalid-void-type */
import type {
  PostTemplateCampaignPreviewRequest,
  PostTemplateCampaignPreviewResponse,
  PostTemplateCreateRequest,
  PostTemplateResponse,
  PostTemplateTextPreviewRequest,
  PostTemplateTextPreviewResponse,
  PostTemplateUpdateRequest,
} from '../../types'
import api from '../api'

const postsTemplatesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPostTemplates: builder.query<PostTemplateResponse[], void>({
      query: () => ({
        url: 'api/post-templates',
        method: 'GET',
        providesTags: (result?: PostTemplateResponse[]) =>
          Array.isArray(result) && result.length !== 0
            ? result.map(({ id }: PostTemplateResponse) => ({ type: 'Post-templates', id }))
            : ['Post-templates'],
      }),
    }),
    createPostTemplate: builder.mutation<PostTemplateResponse, PostTemplateCreateRequest>({
      query: (body) => ({
        url: 'api/post-templates',
        method: 'POST',
        body,
        invalidatesTags: ['Post-templates'],
      }),
    }),
    editPostTemplate: builder.mutation<PostTemplateResponse, PostTemplateUpdateRequest>({
      query: (body) => ({
        url: 'api/post-templates',
        method: 'PUT',
        body,
        invalidatesTags: (result: unknown, error: unknown, body: PostTemplateUpdateRequest) => [
          { type: 'Post-templates', id: body.id },
        ],
      }),
    }),
    deletePostTemplate: builder.mutation<void, string>({
      query: (id) => ({
        url: `api/post-templates/${id}`,
        method: 'DELETE',
        invalidatesTags: (result: unknown, error: unknown, id: string) => [
          { type: 'Post-templates', id },
        ],
      }),
    }),
    getCampaignPreview: builder.query<
      PostTemplateCampaignPreviewResponse,
      PostTemplateCampaignPreviewRequest
    >({
      query: (body) => ({
        url: 'api/post-templates/campaign-preview',
        method: 'POST',
        body,
      }),
    }),
    getTextPreview: builder.query<PostTemplateTextPreviewResponse, PostTemplateTextPreviewRequest>({
      query: (body) => ({
        url: 'api/post-templates/text-preview',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetPostTemplatesQuery,
  useCreatePostTemplateMutation,
  useEditPostTemplateMutation,
  useDeletePostTemplateMutation,
  useGetCampaignPreviewQuery,
  useGetTextPreviewQuery,
  useLazyGetTextPreviewQuery,
} = postsTemplatesApi

export default postsTemplatesApi
