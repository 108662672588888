import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { type FC } from 'react'

import SocialAccountAvatar from '../../../../components/views/SocialAccountAvatar/SocialAccountAvatar'
import useSocialAccount from '../../../../hooks/useSocialAccount'
import { type PostType } from '../../../../types'
import { isNotNullable } from '../../../../utils/typesHelper'
import PostTypeIcon from '../PostTypeIcon/PostTypeIcon'

const PostAccountContainer: FC<{ socialAccountId?: string; postType?: PostType }> = ({
  socialAccountId,
  postType = 'POST',
}) => {
  const { socialAccount } = useSocialAccount(socialAccountId ?? '', {
    skip: socialAccountId === undefined,
  })

  return (
    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
      {isNotNullable(socialAccount) && (
        <SocialAccountAvatar size="md" socialAccount={socialAccount} />
      )}
      <Box>
        <Typography
          sx={{ maxWidth: 90, fontSize: 'sm', lineHeight: 'sm', fontWeight: 'md' }}
          component="span"
        >
          {socialAccount?.name}
        </Typography>
        <Box sx={{ display: 'flex', gap: 0.75, mt: '2px', alignItems: 'center' }}>
          <PostTypeIcon
            size={16}
            postType={postType}
            // opacity={0.8}
            color="var(--joy-palette-neutral-400)"
          />
          <Typography
            sx={{ fontWeight: 500, textTransform: 'capitalize', color: 'text.tertiary' }}
            component="span"
          >
            {postType?.toLocaleLowerCase()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default PostAccountContainer
