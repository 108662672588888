/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  type PaymentHistoryResponse,
  type StartCheckoutSessionRequest,
  type StartCheckoutSessionResponse,
  type SubscriptionPlanGroupResponse,
  type SubscriptionResponse,
  type SubscriptionStatus,
} from '../../types'
import api from '../api'

const paymentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    plans: builder.query<SubscriptionPlanGroupResponse[], void>({
      query: () => ({
        url: 'api/subscriptions/plans',
        method: 'GET',
      }),
    }),
    payments: builder.query<PaymentHistoryResponse[], void>({
      query: () => ({
        url: 'api/subscriptions/payments/history',
        method: 'GET',
      }),
    }),
    checkout: builder.mutation<
      StartCheckoutSessionResponse & { redirectDomain?: string }, // hidden params
      StartCheckoutSessionRequest
    >({
      query: (body) => ({
        url: 'api/subscriptions/payments/start-checkout',
        method: 'POST',
        body,
      }),
    }),
    subscribeStatus: builder.query<void | Partial<SubscriptionStatus>, void>({
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const stream = new EventSource('api/subscriptions/status', { withCredentials: true })

        await cacheDataLoaded

        try {
          stream.addEventListener('subscription-update', (event) => {
            const subscriptionStatus = JSON.parse(event.data) as Partial<SubscriptionStatus>
            updateCachedData(() => subscriptionStatus)
            stream.close()
          })
        } catch (error) {
          console.log(error)
        }

        await cacheEntryRemoved
        stream.close()
      },
      queryFn: () => {
        return { data: undefined }
      },
    }),
    status: builder.query<Partial<SubscriptionStatus>, void>({
      query: () => ({
        url: 'api/subscriptions/status',
        method: 'GET',
      }),
    }),
    cancel: builder.mutation<void, SubscriptionResponse>({
      query: (body) => ({
        url: 'api/subscriptions/cancel',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useCheckoutMutation,
  useSubscribeStatusQuery,
  usePlansQuery,
  usePaymentsQuery,
  useCancelMutation,
  useStatusQuery,
} = paymentApi

export default paymentApi
