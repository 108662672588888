import { createSelector } from '@reduxjs/toolkit'
import {
  type BaseQueryFn,
  type TypedUseQueryHookResult,
  type TypedUseQueryStateResult,
} from '@reduxjs/toolkit/query/react'
import { useMemo } from 'react'

import { useUserQuery } from '../store/endpoints/root'
import { type UserResponse, type UserSocialAccountResponse, type WithRequired } from '../types'

export type NotEmptySocialAccount = WithRequired<UserSocialAccountResponse, 'id' | 'platform'>

type UseUserQueryHookResult = TypedUseQueryHookResult<UserResponse, void, BaseQueryFn>

type UseSocialAccountsResult = UseUserQueryHookResult & {
  socialAccounts?: NotEmptySocialAccount[]
}

const useSocialAccounts = (options?: Record<string, unknown>): UseSocialAccountsResult => {
  const selectSocialAccounts = useMemo(
    () =>
      createSelector(
        // for skip unnecessary renders, bc filter create new instance each time
        (result: TypedUseQueryStateResult<UserResponse, void, BaseQueryFn>) => result.data,
        (data?: UserResponse) =>
          data?.socialAccounts?.filter(
            (socialAccount): socialAccount is NotEmptySocialAccount =>
              socialAccount.id !== undefined && socialAccount.platform !== undefined,
          ),
      ),
    [],
  )

  return useUserQuery(undefined, {
    selectFromResult: (result) => ({
      socialAccounts: selectSocialAccounts(result),
      ...result,
    }),
    ...options,
  })
}

export default useSocialAccounts
