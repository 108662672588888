import Button from '@mui/joy/Button'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import type { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import PageWithBackground from '../../components/views/PageWithBackground/PageWithBackground'
import { useLoginDevMutation } from '../../store/endpoints/auth'

const LoginPage = (): ReactElement => {
  const [devLogin, { isLoading }] = useLoginDevMutation()

  const navigate = useNavigate()

  const handleDevLoginClick = async (): Promise<void> => {
    await devLogin('970702e5-7377-4c03-bc31-029ccc8db202')
    navigate('/')
  }

  return (
    <PageWithBackground>
      <Stack gap={4} sx={{ mb: 2 }}>
        <Stack gap={1}>
          <svg
            width="132"
            height="36"
            viewBox="0 0 132 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 25.0002H25.8016V17.2004L31 11H10.1984V18.7998L5 25.0002Z"
              stroke="#171A1C"
              strokeWidth="4.00006"
            />
            <g clipPath="url(#clip0_2246_3231)">
              <rect x="44" y="16" width="4" height="4" rx="2" fill="#CDD7E1" />
              <rect x="52" y="16" width="4" height="4" rx="2" fill="#CDD7E1" />
              <rect x="60" y="16" width="4" height="4" rx="2" fill="#CDD7E1" />
              <rect x="68" y="16" width="4" height="4" rx="2" fill="#CDD7E1" />
              <rect x="76" y="16" width="4" height="4" rx="2" fill="#CDD7E1" />
              <rect x="84" y="16" width="4" height="4" rx="2" fill="#CDD7E1" />
            </g>
            <rect x="96.375" y="0.375" width="35.25" height="35.25" rx="17.625" fill="#F0F4F8" />
            <rect
              x="96.375"
              y="0.375"
              width="35.25"
              height="35.25"
              rx="17.625"
              stroke="#CDD7E1"
              strokeWidth="0.75"
            />
            <g clipPath="url(#clip1_2246_3231)">
              <path
                d="M120.625 11.5012C120.611 11.4007 120.523 11.3449 120.45 11.3388C120.377 11.3327 118.953 11.3112 118.953 11.3112C118.953 11.3112 117.763 10.1595 117.645 10.0424C117.527 9.92525 117.298 9.96061 117.208 9.98714C117.207 9.98769 116.985 10.0562 116.61 10.1717C116.547 9.96946 116.455 9.72079 116.324 9.47102C115.9 8.66533 115.279 8.23928 114.529 8.23818C114.528 8.23818 114.527 8.23818 114.526 8.23818C114.474 8.23818 114.422 8.24315 114.37 8.24757C114.348 8.22105 114.326 8.19508 114.302 8.16966C113.976 7.82152 113.557 7.65188 113.055 7.6668C112.086 7.69443 111.121 8.39125 110.338 9.62906C109.788 10.4999 109.369 11.5941 109.25 12.4412C108.138 12.7844 107.36 13.0242 107.343 13.0297C106.781 13.2054 106.763 13.2226 106.69 13.7498C106.636 14.1482 105.167 25.4631 105.167 25.4631L117.48 27.5839L122.816 26.2627C122.816 26.2627 120.639 11.6018 120.625 11.5012ZM115.994 10.3618C115.71 10.4491 115.388 10.5486 115.038 10.6563C115.031 10.1678 114.973 9.48815 114.744 8.90074C115.479 9.03944 115.841 9.86778 115.994 10.3618ZM114.394 10.8553C113.749 11.0542 113.045 11.2714 112.338 11.4891C112.537 10.7315 112.914 9.97719 113.376 9.48262C113.548 9.29861 113.789 9.0936 114.074 8.97644C114.342 9.53346 114.401 10.322 114.394 10.8553ZM113.074 8.30891C113.301 8.30394 113.493 8.35367 113.657 8.46088C113.395 8.59626 113.142 8.79077 112.904 9.04441C112.289 9.702 111.817 10.7226 111.629 11.7074C111.043 11.8881 110.469 12.0655 109.941 12.2279C110.274 10.6779 111.579 8.35201 113.074 8.30891Z"
                fill="#95BF47"
              />
              <path
                d="M120.45 11.3394C120.378 11.3333 118.954 11.3118 118.954 11.3118C118.954 11.3118 117.763 10.1601 117.645 10.043C117.602 9.99933 117.542 9.97668 117.48 9.96729L117.481 27.5834L122.817 26.2627C122.817 26.2627 120.639 11.6024 120.626 11.5018C120.612 11.4013 120.523 11.3454 120.45 11.3394Z"
                fill="#5E8E3E"
              />
              <path
                d="M114.523 14.0721L113.903 16.3814C113.903 16.3814 113.211 16.0681 112.392 16.1194C111.19 16.1952 111.177 16.95 111.189 17.1395C111.255 18.1723 113.983 18.3978 114.136 20.817C114.257 22.7202 113.123 24.0221 111.489 24.1249C109.527 24.2481 108.447 23.0959 108.447 23.0959L108.863 21.3354C108.863 21.3354 109.95 22.1521 110.82 22.0974C111.388 22.0615 111.591 21.6012 111.571 21.2757C111.485 19.9285 109.263 20.008 109.123 17.7944C109.005 15.9316 110.233 14.0439 112.944 13.8737C113.988 13.8069 114.523 14.0721 114.523 14.0721Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_2246_3231">
                <rect width="44" height="4" fill="white" transform="translate(44 16)" />
              </clipPath>
              <clipPath id="clip1_2246_3231">
                <rect width="18" height="20.25" fill="white" transform="translate(105 7.5)" />
              </clipPath>
            </defs>
          </svg>

          <Typography level="h3">Sign in with Shopify</Typography>
          <Typography level="body-sm">
            Connect Shopify and import your products automatically.
          </Typography>
        </Stack>
        <Link href="https://www.shopify.com/login" underline="none">
          <Button fullWidth>Sign in with Shopify</Button>
        </Link>
        <Button fullWidth onClick={handleDevLoginClick} loading={isLoading}>
          Dev login
        </Button>
      </Stack>
    </PageWithBackground>
  )
}

export default LoginPage
