import Link from '@mui/joy/Link'
import { type FC } from 'react'
import { TbCaretDownFilled } from 'react-icons/tb'

export type Sort = 'asc' | 'desc'

export interface SortLabelProps {
  label: string
  sort?: Sort | null
  onSortChange?: (newSort: Sort | null) => void
}

const SortLabel: FC<SortLabelProps> = ({ label, sort = null, onSortChange }) => {
  const handleSortClick =
    onSortChange !== undefined
      ? () => {
          // todo: rewrite use generators
          if (sort === null) {
            onSortChange('asc')
          }
          if (sort === 'asc') {
            onSortChange('desc')
          }
          if (sort === 'desc') {
            onSortChange(null)
          }
        }
      : undefined

  return (
    <Link
      underline="none"
      component="button"
      onClick={handleSortClick}
      endDecorator={sort !== null ? <TbCaretDownFilled /> : null}
      sx={{
        color: 'inherit',
        '& svg': {
          transition: '0.2s',
          transform: sort === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
        },
      }}
    >
      {label}
    </Link>
  )
}

export default SortLabel
