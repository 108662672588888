import Chip from '@mui/joy/Chip'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import { type FC, memo } from 'react'
import { Link } from 'react-router-dom'

import { type NavigationListProps } from './types'

const NavigationList: FC<NavigationListProps> = ({ activeId, items, sx, key, ...props }) => {
  return (
    <List
      sx={{
        mt: 3,
        gap: 1,
        '--List-nestedInsetStart': '30px',
        '--ListItem-radius': (theme) => theme.vars.radius.sm,
        ...sx,
      }}
      {...props}
    >
      {items.map(({ id, label, navigationRoute, itemsCount = 0 }, index) => {
        const isActive = activeId === id

        return (
          <ListItem
            key={`${id}${label}`}
            component={Link}
            to={navigationRoute}
            sx={{ textDecoration: 'none' }}
          >
            <ListItemButton selected={isActive} role="menuitem">
              <ListItemContent>
                <Typography level="title-sm">{label}</Typography>
              </ListItemContent>

              {itemsCount !== 0 && (
                <Chip
                  size="sm"
                  color={isActive ? 'primary' : 'neutral'}
                  variant={isActive ? 'solid' : 'plain'}
                >
                  {itemsCount}
                </Chip>
              )}
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

export default memo(NavigationList)
