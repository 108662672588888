import * as yup from 'yup'

import { type MediaValue } from '../../../../../../types'

const checkMediaForVideo = (mediaArray: MediaValue[]): boolean => {
  return mediaArray.some(
    (mediaItem: MediaValue) => mediaItem?.url && mediaItem.url.endsWith('.mp4'),
  )
}

const postsFormSchema = yup.object({
  posts: yup
    .array()
    .of(
      yup
        .object({
          socialName: yup.string().oneOf(['FACEBOOK', 'INSTAGRAM']).required(),
          socialAccountId: yup.string().required('Required'),
          postType: yup.string().oneOf(['POST', 'STORY', 'REEL']).required('Required'),
          productId: yup.string().required('Required'),
          media: yup
            .array()
            .of(
              yup.object({
                frontId: yup.string().required(),
                id: yup.string().required(),
                url: yup.string(),
              }),
            )
            .min(1, 'Field must have at least 1 items')
            .required(),
          caption: yup.string().max(2200, 'Field must be at most 2200 characters'),
        })
        .test('check-video', 'POST cannot contain videos', function (post) {
          const { postType, media } = post
          if (postType === 'POST' && checkMediaForVideo(media)) {
            return this.createError({ message: 'Videos are not allowed in POST type.' })
          }
          return true
        }),
    )
    .min(1)
    .required(),
})

export { postsFormSchema }
