import { type FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { DatePipe } from '../../../../components/views'
import { type CampaignResponse } from '../../../../types'
import SortLabel from '../../../../ui/SortLabel/SortLabel'
import Table from '../../../../ui/Table/Table'
import { isNotNullable } from '../../../../utils/typesHelper'
import PostAccountContainer from '../../../Posts/components/PostAccountContainer/PostAccountContainer'
import { type CampaignTableProps } from '../../types'
import EmptyCampaigns from '../EmptyCampaigns/EmptyCampaigns'
// import BaseSkeletonRow from '../../../Posts/components/base/BaseSkeletonRow'
import PausedCampaignSkeletonRow from './PausedCampaignSkeletonRow'

const CAMPAINGS_SKELETON: CampaignResponse[] = [{ id: 'skeleton1' }]

const PausedCampaignTable: FC<CampaignTableProps> = ({
  isLoading = false,
  campaigns,
  ...props
}) => {
  const navigate = useNavigate()
  return (
    <Table
      items={isLoading ? CAMPAINGS_SKELETON : campaigns}
      isLoading={isLoading}
      renderHeader={() => (
        <tr>
          <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
            {/* <Checkbox
              variant="outlined"
              size="md"
              color="neutral"
              sx={{ verticalAlign: 'text-bottom' }}
            /> */}
          </th>
          <th style={{ width: 730, padding: '12px 6px' }}>
            <SortLabel label="Title" />
          </th>
          <th style={{ width: 220, padding: '12px 6px' }}>
            <SortLabel label="Created on" />
          </th>
          <th style={{ width: 220, padding: '12px 6px' }}>
            <SortLabel label="Page" />
          </th>
        </tr>
      )}
      renderRow={(campaign) => (
        <tr
          key={campaign.id}
          onClick={() => {
            if (campaign.id !== undefined) {
              navigate(campaign.id)
            }
          }}
        >
          <td style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
            {/* <Checkbox
              variant="outlined"
              size="md"
              color="neutral"
              sx={{ verticalAlign: 'text-bottom' }}
            /> */}
          </td>
          <td>{campaign.name}</td>
          <td>
            {isNotNullable((campaign as any)?.createdAt) && (
              <DatePipe date={(campaign as any)?.createdAt} />
            )}
          </td>
          <td>
            <PostAccountContainer
              postType={campaign?.postType}
              socialAccountId={campaign?.socialAccount?.id}
            />
          </td>
        </tr>
      )}
      renderSkeletonRow={(campaign) => <PausedCampaignSkeletonRow key={campaign.id} />}
      renderEmptyTable={() => <EmptyCampaigns />}
      {...props}
    />
  )
}

export default PausedCampaignTable
