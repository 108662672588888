/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {
  type CampaignResponse,
  type PageCampaignResponse,
  type PatchedCampaignCreateRequest,
  type PatchedCampaignUpdateRequest,
} from '../../types'
import api from '../api'

const campaignApi = api.injectEndpoints({
  endpoints: (builder) => ({
    campaigns: builder.query<PageCampaignResponse, string>({
      query: (params: string) => ({
        url: 'api/campaigns',
        method: 'GET',
        params: new URLSearchParams(params),
      }),
      providesTags: ['Campaign'],
    }),
    campaign: builder.query<CampaignResponse, string>({
      query: (campaignId: string) => ({
        url: `api/campaigns/${campaignId}`,
        method: 'GET',
      }),
    }),
    createCampaign: builder.mutation<void, PatchedCampaignCreateRequest>({
      query: (body) => ({
        url: 'api/campaigns',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Campaign'],
    }),
    editCampaign: builder.mutation<void, PatchedCampaignUpdateRequest>({
      query: (body) => ({
        url: 'api/campaigns',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Campaign'],
    }),
    editCampaignStatus: builder.mutation<void, { campaignId: string; status: string }>({
      query: ({ campaignId, status }) => ({
        url: `api/campaigns/${campaignId}/status/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Campaign'],
    }),
  }),
})

export const {
  useCampaignsQuery,
  useCampaignQuery,
  useCreateCampaignMutation,
  useEditCampaignStatusMutation,
  useEditCampaignMutation,
} = campaignApi

export default campaignApi
