import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Skeleton from '@mui/joy/Skeleton'
import Typography from '@mui/joy/Typography'
import { type FC, memo } from 'react'

import BaseDatePipeSkeleton from './BaseDatePipeSkeleton'

const BaseSkeletonRow: FC = () => {
  return (
    <>
      <td style={{ width: 48 }}>
        <Box display="flex" justifyContent="center">
          <Skeleton variant="rectangular" width={18} height={18} />
        </Box>
      </td>
      <td>
        <Box display="flex" justifyContent="center">
          <Skeleton variant="rectangular" width={60} height={60} />
        </Box>
      </td>
      <td>
        <Typography component="span">
          <Skeleton>Product Name: Super Product</Skeleton>
        </Typography>
        <Typography component="span" textAlign="left">
          <Skeleton>Title of product and some info: Super Title</Skeleton>
        </Typography>
      </td>
      <td>
        <BaseDatePipeSkeleton></BaseDatePipeSkeleton>
      </td>
      <td>
        <BaseDatePipeSkeleton></BaseDatePipeSkeleton>
      </td>
      <td>
        <Box display="flex" gap={2}>
          <Avatar>
            <Skeleton></Skeleton>
          </Avatar>
          <div>
            <Typography component="span">
              <Skeleton>Account name</Skeleton>
            </Typography>
            <Typography component="span" textAlign="left">
              <Skeleton>Post type</Skeleton>
            </Typography>
          </div>
        </Box>
      </td>
    </>
  )
}

export default memo(BaseSkeletonRow)
