import Box, { type BoxProps } from '@mui/joy/Box'
import { type FC } from 'react'

const RootBox: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box
      width="100%"
      flexGrow={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      minWidth={740}
      height="100%"
      overflow="auto"
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          maxWidth: 1308,
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default RootBox
