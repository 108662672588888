import FacebookLogin from '@greatsumini/react-facebook-login'
import Button from '@mui/joy/Button'
import type { FC, PropsWithChildren } from 'react'

import { useConnectFBMutation } from '../../../../store/endpoints/root'

const FACEBOOK_API_ID = '1464980804329389'
const FACEBOOK_SCOPE =
  'email,read_insights,publish_video,pages_manage_posts,pages_show_list,business_management,instagram_basic,instagram_content_publish,pages_read_user_content,pages_read_engagement,pages_manage_metadata'

const FacebookButtonContainer: FC<PropsWithChildren> = ({ children }) => {
  const [connectFB, { isLoading: isConnectFBLoading }] = useConnectFBMutation()

  return (
    <FacebookLogin
      appId={FACEBOOK_API_ID}
      scope={FACEBOOK_SCOPE}
      render={({ onClick }) => (
        <Button variant="outlined" onClick={onClick} loading={isConnectFBLoading}>
          {children}
        </Button>
      )}
      onSuccess={({ accessToken }) => {
        void connectFB({ accessToken })
      }}
    />
  )
}

export default FacebookButtonContainer
