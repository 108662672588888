import Alert, { type AlertProps } from '@mui/joy/Alert'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import type { FC } from 'react'
import { TbInfoSquareRounded } from 'react-icons/tb'

import { HOW_TO_SWITCH_ACCOUNT_URL } from '../../../../../utils/constants'

const BusinessAccountRequiredAlert: FC<AlertProps> = (props) => {
  return (
    <Alert variant="soft" color="neutral" startDecorator={<TbInfoSquareRounded />} {...props}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography>Instagram business account required</Typography>
        <Link href={HOW_TO_SWITCH_ACCOUNT_URL} target="_blank" sx={{ fontSize: 'inherit' }}>
          How to switch to business?
        </Link>
      </Stack>
    </Alert>
  )
}

export default BusinessAccountRequiredAlert
