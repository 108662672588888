import type { DetailedHTMLProps, VideoHTMLAttributes } from 'react'
import { forwardRef } from 'react'
import { getVideoExtension } from '../PostPreview/PostPreview.utils'

export interface VideoProps extends DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  src?: string
}

const Video = forwardRef<HTMLVideoElement, VideoProps>(({ src, ...rest }, ref) => {
  const extensionWithoutDot = src !== undefined ? getVideoExtension(src)?.replace('.', '') : null

  return (
        <video ref={ref} {...rest}>
            <source src={src} type={`video/${extensionWithoutDot ?? 'mp4'}`} />
            Your browser does not support the video tag.
        </video>
  )
})

Video.displayName = 'Video'

export default Video
