import theme from '../../theme'

export const getVideoExtension = (url: string): string | null => {
  return url.match(/\.(mp4|avi|mov|wmv|flv|webm|mkv|m4v)/)?.[0] ?? null
}

export const isVideo = (url: string | undefined): boolean => {
  if (url === undefined) return false
  return Boolean(getVideoExtension(url))
}

export const parseDescription = (description: string, regExp: RegExp): string => {
  return description.replace(regExp, (match) => {
    return `<span style="color:${theme.palette.accent100};">${match}</span>`
  })
}
