import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'

import { TimeSchedulerModal } from '../TimeSchedulerModal/TimeSchedulerModal'

export const TimeSchedulerContext = createContext<() => Promise<string>>(() => {
  throw Error('Time scheduler not resolved')
})

const TimeSchedulerProvider: FC<PropsWithChildren> = ({ children }) => {
  const resolver = useRef<{
    resolve: (time: string) => void
    reject: () => void
  }>()

  const [isOpen, setIsOpen] = useState(false)

  const scheduleTime = useCallback(async (): Promise<string> => {
    setIsOpen(true)

    return await new Promise<string>((resolve, reject) => {
      resolver.current = { resolve, reject }
    })
  }, [resolver])

  const handleTimeConfirmation = (date: string): void => {
    setIsOpen(false)
    resolver.current?.resolve(date)
  }

  const handleDecline = (): void => {
    setIsOpen(false)

    resolver.current?.reject()
  }

  return (
    <TimeSchedulerContext.Provider value={scheduleTime}>
      <TimeSchedulerModal
        open={isOpen}
        onTimeConfirmed={handleTimeConfirmation}
        onDecline={handleDecline}
        onClose={handleDecline}
      />
      {children}
    </TimeSchedulerContext.Provider>
  )
}

export default TimeSchedulerProvider
