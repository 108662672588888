import Card from '@mui/joy/Card'
import Divider from '@mui/joy/Divider'
import Skeleton from '@mui/joy/Skeleton'
import Typography from '@mui/joy/Typography'
import { type FC, useCallback } from 'react'

import { ReactComponent as MetaIcon } from '../../../../assets/img/meta.svg'
import useConfirm from '../../../../hooks/useConfirm/useConfirm'
import useSocialAccounts, { type NotEmptySocialAccount } from '../../../../hooks/useSocialAccounts'
import { useDeleteSocialAccountMutation } from '../../../../store/endpoints/root'
import HorizontalCardStack from '../../../../ui/HorizontalCardStack/HorizontalCardStack'
import FacebookButtonContainer from '../FacebookButtonContainer/FacebookButtonContainer'
import BusinessAccountRequiredAlert from './components/BusinessAccountRequiredAlert'
import SocialAccounts from './components/SocialsAccounts'

const SocialAccountsContainer: FC = () => {
  const [deleteSocialAccount] = useDeleteSocialAccountMutation()
  const { socialAccounts, isLoading: isSocialAccountsLoading } = useSocialAccounts()

  const confirm = useConfirm()

  const handleDeleteSocialAccountClick = useCallback(
    async (socialAccount: NotEmptySocialAccount): Promise<void> => {
      const accountName = socialAccount.name ?? 'account'

      const isConfirmed = await confirm({
        title: 'Delete confirmation',
        question: `Do you really want to delete ${accountName}?`,
        confirm: `Delete ${accountName}`,
      })

      if (!isConfirmed) return

      await deleteSocialAccount({
        id: socialAccount.id,
        platform: socialAccount.platform,
      })
    },
    [],
  )

  return (
    <>
      <BusinessAccountRequiredAlert />

      {isSocialAccountsLoading && <Skeleton variant="rectangular" height={200} />}

      {Array.isArray(socialAccounts) && socialAccounts.length !== 0 && (
        <Card variant="outlined" sx={{ borderRadius: 'md' }}>
          <HorizontalCardStack
            icon={<MetaIcon />}
            content={<Typography fontWeight="md">Some account</Typography>}
            action={
              <FacebookButtonContainer>
                {socialAccounts.length === 0 ? 'Add account' : 'Manage'}
              </FacebookButtonContainer>
            }
          />

          <Divider />

          <SocialAccounts
            socialAccounts={socialAccounts}
            onDeleteClick={handleDeleteSocialAccountClick}
          />
        </Card>
      )}
    </>
  )
}

export default SocialAccountsContainer
