import { type BaseSyntheticEvent } from 'react'

import {
  type CampaignResponse,
  type PatchedCampaignCreateRequest,
  type UserResponse,
} from '../../types'
import { type PostFormValues } from '../Post/components/PostsFormContainer/components/PostsForm/components/PostForm/PostForm.types'
import { CampaignsSubmitType } from './CampaignsFormSubmitter.type'
import { type CampaignFormValues } from './components/CampaignForm/CampaignForm.types'

export const getSubmitType = (event?: BaseSyntheticEvent): CampaignsSubmitType => {
  const submitterName = ((event?.nativeEvent as SubmitEvent)?.submitter as HTMLButtonElement)?.name

  switch (submitterName) {
    case CampaignsSubmitType.Pause:
    case CampaignsSubmitType.Remove:
    case CampaignsSubmitType.Instant:
    case CampaignsSubmitType.Renew:
    case CampaignsSubmitType.Update:
      return submitterName
    default:
      throw new Error(`Unknown SubmitterName: ${submitterName}`)
  }
}

export const initializeCampaignsFormByUser = (user: UserResponse): Partial<CampaignFormValues> => {
  const sourceAccount =
    user?.sourceAccounts?.length === 1 ? user?.sourceAccounts?.[0]?.id : undefined

  const someInstagramSocialAccount = user?.socialAccounts?.find(
    (socialAccount) => socialAccount?.platform === 'INSTAGRAM',
  )

  const socialAccount =
    someInstagramSocialAccount === undefined
      ? user?.socialAccounts?.length === 1
        ? user?.socialAccounts?.[0]
        : undefined
      : someInstagramSocialAccount

  return {
    socialName: socialAccount?.platform ?? 'INSTAGRAM',
    type: 'NEW_ITEM',
    postType: 'POST',
    productRuleType: 'COLLECTIONS',
    mediaRule: 'FIRST',

    socialAccountId: socialAccount?.id ?? '',
    sourceAccountId: sourceAccount,
    postTemplates: [],
    collectionIds: [],
    tags: [],
  }
}

export const initializeCampaignsFormByCampaign = (
  campaign: CampaignResponse,
): Partial<CampaignFormValues> => {
  const map = new Map()

  map.set('socialName', campaign?.socialAccount?.platform ?? 'INSTAGRAM')
  map.set('socialAccountId', campaign?.socialAccount?.id)

  map.set('name', campaign?.name)
  map.set('type', campaign?.selectionType)
  map.set('sourceAccountId', campaign?.sourceAccount?.id)
  map.set('collectionIds', (campaign?.productRule as any)?.collections ?? [])
  map.set('tags', (campaign?.productRule as any)?.tags ?? [])

  map.set('productRuleType', (campaign?.productRule as any)?.type)
  map.set('postType', campaign?.postType)
  map.set('mediaRule', campaign?.mediaRule)
  map.set('postTemplates', campaign?.postTemplates)

  const [hours, minutes] = (campaign.scheduleRule?.time as string)?.split(':') ?? []

  map.set('time', hours !== undefined && minutes !== undefined ? `${hours}:${minutes}` : undefined)
  map.set('days', campaign.scheduleRule?.days ?? [])

  for (const [key, value] of map) {
    if (value === null || value === undefined) {
      map.delete(key)
    }
  }

  return Object.fromEntries(map)
}

export const toPostForm = (campaignFormValues: CampaignFormValues): Partial<PostFormValues> => ({
  socialName: campaignFormValues.socialName,
  socialAccountId: campaignFormValues.socialAccountId,

  postType: campaignFormValues.postType,
  // media: MediaValue[]
  caption: campaignFormValues?.postTemplates?.[0],

  platform: 'SHOPIFY',
  sourceAccountId: campaignFormValues.sourceAccountId,
})

export const toCampaign = (campaign: CampaignFormValues): PatchedCampaignCreateRequest => {
  const { time } = campaign
  const productRule =
    campaign.productRuleType === 'COLLECTIONS'
      ? { type: 'COLLECTIONS', collections: campaign.collectionIds ?? [] }
      : { type: 'TAGS', tags: campaign.tags ?? [] }

  return {
    name: campaign.name,
    selectionType: campaign.type,
    socialAccount: {
      platform: campaign.socialName,
      id: campaign.socialAccountId,
    },
    postType: campaign.postType,
    postTemplates: campaign.postTemplates,
    mediaRule: campaign.mediaRule,
    productRule,
    sourceAccount: {
      platform: 'SHOPIFY' as const,
      id: campaign.sourceAccountId,
    },
    scheduleRule: {
      days: campaign.days,
      time: `${time}:00.000`,
    },
  }
}
