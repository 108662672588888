import { Alert, Snackbar } from '@mui/joy'
import { type FC } from 'react'
import { TbExclamationCircle } from 'react-icons/tb'

import { type NotificationViewProps } from './types'

const ErrorSnackbar: FC<NotificationViewProps> = ({ notification, ...props }) => (
  <Snackbar color="danger" variant="soft" sx={{ p: 0 }} {...props}>
    <Alert
      color="danger"
      variant="solid"
      sx={{ width: '100%' }}
      startDecorator={<TbExclamationCircle size={24} />}
    >
      {notification.message}
    </Alert>
  </Snackbar>
)

export default ErrorSnackbar
