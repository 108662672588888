import { type Draft, type PayloadAction } from '@reduxjs/toolkit'

import { type Notification, type NotificationsSlice } from './types'

export const pushNotificationReducer = (
  state: Draft<NotificationsSlice>,
  action: PayloadAction<Notification>,
): void => {
  state.notifications.push(action.payload)
}

export const removeNotificationReducer = (
  state: Draft<NotificationsSlice>,
  action: PayloadAction<string>,
): void => {
  state.notifications = state.notifications.filter(({ id }) => id !== action.payload)
}
