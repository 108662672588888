import type { ReactElement } from 'react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import Layout from './core/Layout'
import Router from './core/Router'
import reportWebVitals from './reportWebVitals'

const Index = (): ReactElement => {
  return (
    <Layout>
      <Router />
    </Layout>
  )
}

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement)

root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
