import { styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import type { LinearProgressProps } from '@mui/material/LinearProgress'

const ProgressBar = styled(LinearProgress)<LinearProgressProps>(({ theme }) => ({
  borderRadius: theme.borderRadius.medium,
  height: '2px',
  backgroundColor: theme.palette.white50,
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.white100
  }
}))

export default ProgressBar
