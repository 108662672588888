import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import type { PropsWithChildren } from 'react'
import { forwardRef } from 'react'

interface AnimationBoxProps extends PropsWithChildren {
  // label: string
  extension?: string
  color?: 'primary'
}

export const AnimationBox = forwardRef<HTMLDivElement, AnimationBoxProps>(
  ({ color, children }: AnimationBoxProps, ref) => (
    <Fade in timeout={300}>
      <Box ref={ref} display="flex" flexDirection="column" alignItems="center" width="68px">
        {children}
      </Box>
    </Fade>
  ),
)

AnimationBox.displayName = 'AnimationBox'
