import { Skeleton, Stack } from '@mui/joy'
import Box from '@mui/joy/Box'
import { type FC, memo } from 'react'

const PostsFormSkeleton: FC = () => {
  return (
    <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
      <Stack display="flex" flexDirection="row" gap={10}>
        <Box sx={{ px: 2 }}>
          <Stack sx={{ mb: 2 }} gap={2} direction="row">
            <Skeleton variant="rectangular" width={190} height={44} />
            <Skeleton variant="rectangular" width={190} height={44} />
            <Skeleton variant="rectangular" width={190} height={44} />
          </Stack>
          <Skeleton variant="rectangular" width={610} height={635} sx={{ mb: 1 }} />
        </Box>
        <Stack
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth={470}
          gap={2.5}
          sx={{ mt: 7.5 }}
        >
          <Skeleton variant="rectangular" width={470} height={51} />
          <Skeleton variant="rectangular" width={470} height={41} />
          <Skeleton variant="rectangular" width={470} height={484} />
        </Stack>
      </Stack>
    </Box>
  )
}

export default memo(PostsFormSkeleton)
